import { CompanyAccountingIntegrationType } from '../api/company-accounting-integration-setup'
import { BookingRule } from '../model/accountingIntegration'
import { ApiKeyScope } from '../model/apiKey'
import { AsynchronousScheduleType } from '../model/asynchronousSchedule'
import { AsynchronousTaskStatus } from '../model/asynchronousTask'
import { GroupUserType } from '../model/companyGroup'
import { UserPermission } from '../model/companyUser'
import { DepartmentPermission } from '../model/departmentUser'
import { DocumentSignatureType } from '../model/document'
import { NationalIDType } from '../model/employee'
import { LeaveSubTypeName, LeaveTypeName, LeaveUnit } from '../model/leaveType'
import { NetsMessageType } from '../model/netsMessage'
import { OneTimePayCategory, OneTimePayType } from '../model/oneTimePay'
import PayRoll, { PayRollRunApprovalType, PayRollTaskType, UserPayRollStatus } from '../model/payRoll'
import { DeviationType } from '../model/payRollDeviation'
import PensionCompany, { PensionCustomerIDType } from '../model/pensionCompany'
import { BenefitDefinition, BenefitType, PensionDefinition } from '../model/remuneration'
import SalaryCycle, { SalaryFrequency } from '../model/salaryCycle'
import { SupplementTypeName } from '../model/supplementType'
import { TaxCardType } from '../model/taxCard'
import { TaxCardRequestType } from '../model/taxCardRequest'
import { TimeRegistrationClass } from '../model/timeRegistration'
import { PaymentMethod, TransferDestinationType, TransferType } from '../model/transfer'
import { EmploymentType, OffsetDisposition, SourceType } from '../model/types'
import { VacationNamedDay } from '../model/vacationCalendar'
import CompanySize from '../types/company-size'
import CountryCode from '../types/country-code'
import FamilyLeaveFund from '../types/family-leave-fund'
import Gender from '../types/gender'
import IncomeType from '../types/income-type'
import Language from '../types/language'
import LeaveDuration from '../types/leave-duration'
import MfaChannel from '../types/mfa-channel'
import PensionScheme from '../types/pension-scheme'
import PreferredTaxCardType from '../types/preferred-tax-card-type'
import RemunerationType from '../types/remuneration-type'
import TransferSettingIntegration from '../types/transfer-setting-integration'
import UserCompanyType from '../types/user-company-type'
import { formatShortDate, getDate, isTimeBefore } from './date-utils'
import { EmployeePayType } from './employee-utils'
import { logWarning } from './log-utils'
import { formatDisplayNumber, formatOrdinalSuffix } from './number-utils'
import { convertPayRollStatus } from './pay-roll-utils'
import { t } from './translation-utils'

export function formatAccountingIntegration(integration: CompanyAccountingIntegrationType) {
  switch (integration) {
    // only Billy and e-conomic has names distinct from their types
    case 'BillysBilling':
      return 'Billy'
    case 'Economics':
      return 'e-conomic'
    case 'None':
      return t('accounting.no_integration')
    default:
      return integration // don't matter just return its type as name
  }
}

export function formatBookingRule(rule: BookingRule) {
  switch (rule) {
    case 'Book':
      return t('accounting.booking_rule.book')
    case 'Daybook':
      return t('accounting.booking_rule.daybook')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing booking rule formatting: `' + rule + '`')
      }
      return t('common.unknown')
  }
}

export function formatCompanySize(size: CompanySize) {
  switch (size) {
    case CompanySize.SMALL:
      return t('company.company_size.small')
    case CompanySize.LARGE:
      return t('company.company_size.large')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing company size formatting: `' + size + '`')
      }
      return t('common.unknown')
  }
}

export function formatCountryCode(code: CountryCode) {
  switch (code) {
    case CountryCode.AF:
      return t('country_code.af')
    case CountryCode.AX:
      return t('country_code.ax')
    case CountryCode.AL:
      return t('country_code.al')
    case CountryCode.DZ:
      return t('country_code.dz')
    case CountryCode.AS:
      return t('country_code.as')
    case CountryCode.AD:
      return t('country_code.ad')
    case CountryCode.AO:
      return t('country_code.ao')
    case CountryCode.AI:
      return t('country_code.ai')
    case CountryCode.AQ:
      return t('country_code.aq')
    case CountryCode.AG:
      return t('country_code.ag')
    case CountryCode.AR:
      return t('country_code.ar')
    case CountryCode.AM:
      return t('country_code.am')
    case CountryCode.AW:
      return t('country_code.aw')
    case CountryCode.AU:
      return t('country_code.au')
    case CountryCode.AT:
      return t('country_code.at')
    case CountryCode.AZ:
      return t('country_code.az')
    case CountryCode.BS:
      return t('country_code.bs')
    case CountryCode.BH:
      return t('country_code.bh')
    case CountryCode.BD:
      return t('country_code.bd')
    case CountryCode.BB:
      return t('country_code.bb')
    case CountryCode.BY:
      return t('country_code.by')
    case CountryCode.BE:
      return t('country_code.be')
    case CountryCode.BZ:
      return t('country_code.bz')
    case CountryCode.BJ:
      return t('country_code.bj')
    case CountryCode.BM:
      return t('country_code.bm')
    case CountryCode.BT:
      return t('country_code.bt')
    case CountryCode.BO:
      return t('country_code.bo')
    case CountryCode.BQ:
      return t('country_code.bq')
    case CountryCode.BA:
      return t('country_code.ba')
    case CountryCode.BW:
      return t('country_code.bw')
    case CountryCode.BV:
      return t('country_code.bv')
    case CountryCode.BR:
      return t('country_code.br')
    case CountryCode.IO:
      return t('country_code.io')
    case CountryCode.BN:
      return t('country_code.bn')
    case CountryCode.BG:
      return t('country_code.bg')
    case CountryCode.BF:
      return t('country_code.bf')
    case CountryCode.BI:
      return t('country_code.bi')
    case CountryCode.CV:
      return t('country_code.cv')
    case CountryCode.KH:
      return t('country_code.kh')
    case CountryCode.CM:
      return t('country_code.cm')
    case CountryCode.CA:
      return t('country_code.ca')
    case CountryCode.KY:
      return t('country_code.ky')
    case CountryCode.CF:
      return t('country_code.cf')
    case CountryCode.TD:
      return t('country_code.td')
    case CountryCode.CL:
      return t('country_code.cl')
    case CountryCode.CN:
      return t('country_code.cn')
    case CountryCode.CX:
      return t('country_code.cx')
    case CountryCode.CC:
      return t('country_code.cc')
    case CountryCode.CO:
      return t('country_code.co')
    case CountryCode.KM:
      return t('country_code.km')
    case CountryCode.CG:
      return t('country_code.cg')
    case CountryCode.CD:
      return t('country_code.cd')
    case CountryCode.CK:
      return t('country_code.ck')
    case CountryCode.CR:
      return t('country_code.cr')
    case CountryCode.CI:
      return t('country_code.ci')
    case CountryCode.HR:
      return t('country_code.hr')
    case CountryCode.CU:
      return t('country_code.cu')
    case CountryCode.CW:
      return t('country_code.cw')
    case CountryCode.CY:
      return t('country_code.cy')
    case CountryCode.CZ:
      return t('country_code.cz')
    case CountryCode.DK:
      return t('country_code.dk')
    case CountryCode.DJ:
      return t('country_code.dj')
    case CountryCode.DM:
      return t('country_code.dm')
    case CountryCode.DO:
      return t('country_code.do')
    case CountryCode.EC:
      return t('country_code.ec')
    case CountryCode.EG:
      return t('country_code.eg')
    case CountryCode.SV:
      return t('country_code.sv')
    case CountryCode.GQ:
      return t('country_code.gq')
    case CountryCode.ER:
      return t('country_code.er')
    case CountryCode.EE:
      return t('country_code.ee')
    case CountryCode.SZ:
      return t('country_code.sz')
    case CountryCode.ET:
      return t('country_code.et')
    case CountryCode.FK:
      return t('country_code.fk')
    case CountryCode.FO:
      return t('country_code.fo')
    case CountryCode.FJ:
      return t('country_code.fj')
    case CountryCode.FI:
      return t('country_code.fi')
    case CountryCode.FR:
      return t('country_code.fr')
    case CountryCode.GF:
      return t('country_code.gf')
    case CountryCode.PF:
      return t('country_code.pf')
    case CountryCode.TF:
      return t('country_code.tf')
    case CountryCode.GA:
      return t('country_code.ga')
    case CountryCode.GM:
      return t('country_code.gm')
    case CountryCode.GE:
      return t('country_code.ge')
    case CountryCode.DE:
      return t('country_code.de')
    case CountryCode.GH:
      return t('country_code.gh')
    case CountryCode.GI:
      return t('country_code.gi')
    case CountryCode.GR:
      return t('country_code.gr')
    case CountryCode.GL:
      return t('country_code.gl')
    case CountryCode.GD:
      return t('country_code.gd')
    case CountryCode.GP:
      return t('country_code.gp')
    case CountryCode.GU:
      return t('country_code.gu')
    case CountryCode.GT:
      return t('country_code.gt')
    case CountryCode.GG:
      return t('country_code.gg')
    case CountryCode.GN:
      return t('country_code.gn')
    case CountryCode.GW:
      return t('country_code.gw')
    case CountryCode.GY:
      return t('country_code.gy')
    case CountryCode.HT:
      return t('country_code.ht')
    case CountryCode.HM:
      return t('country_code.hm')
    case CountryCode.VA:
      return t('country_code.va')
    case CountryCode.HN:
      return t('country_code.hn')
    case CountryCode.HK:
      return t('country_code.hk')
    case CountryCode.HU:
      return t('country_code.hu')
    case CountryCode.IS:
      return t('country_code.is')
    case CountryCode.IN:
      return t('country_code.in')
    case CountryCode.ID:
      return t('country_code.id')
    case CountryCode.IR:
      return t('country_code.ir')
    case CountryCode.IQ:
      return t('country_code.iq')
    case CountryCode.IE:
      return t('country_code.ie')
    case CountryCode.IM:
      return t('country_code.im')
    case CountryCode.IL:
      return t('country_code.il')
    case CountryCode.IT:
      return t('country_code.it')
    case CountryCode.JM:
      return t('country_code.jm')
    case CountryCode.JP:
      return t('country_code.jp')
    case CountryCode.JE:
      return t('country_code.je')
    case CountryCode.JO:
      return t('country_code.jo')
    case CountryCode.KZ:
      return t('country_code.kz')
    case CountryCode.KE:
      return t('country_code.ke')
    case CountryCode.KI:
      return t('country_code.ki')
    case CountryCode.KP:
      return t('country_code.kp')
    case CountryCode.KR:
      return t('country_code.kr')
    case CountryCode.KW:
      return t('country_code.kw')
    case CountryCode.KG:
      return t('country_code.kg')
    case CountryCode.LA:
      return t('country_code.la')
    case CountryCode.LV:
      return t('country_code.lv')
    case CountryCode.LB:
      return t('country_code.lb')
    case CountryCode.LS:
      return t('country_code.ls')
    case CountryCode.LR:
      return t('country_code.lr')
    case CountryCode.LY:
      return t('country_code.ly')
    case CountryCode.LI:
      return t('country_code.li')
    case CountryCode.LT:
      return t('country_code.lt')
    case CountryCode.LU:
      return t('country_code.lu')
    case CountryCode.MO:
      return t('country_code.mo')
    case CountryCode.MK:
      return t('country_code.mk')
    case CountryCode.MG:
      return t('country_code.mg')
    case CountryCode.MW:
      return t('country_code.mw')
    case CountryCode.MY:
      return t('country_code.my')
    case CountryCode.MV:
      return t('country_code.mv')
    case CountryCode.ML:
      return t('country_code.ml')
    case CountryCode.MT:
      return t('country_code.mt')
    case CountryCode.MH:
      return t('country_code.mh')
    case CountryCode.MQ:
      return t('country_code.mq')
    case CountryCode.MR:
      return t('country_code.mr')
    case CountryCode.MU:
      return t('country_code.mu')
    case CountryCode.YT:
      return t('country_code.yt')
    case CountryCode.MX:
      return t('country_code.mx')
    case CountryCode.FM:
      return t('country_code.fm')
    case CountryCode.MD:
      return t('country_code.md')
    case CountryCode.MC:
      return t('country_code.mc')
    case CountryCode.MN:
      return t('country_code.mn')
    case CountryCode.ME:
      return t('country_code.me')
    case CountryCode.MS:
      return t('country_code.ms')
    case CountryCode.MA:
      return t('country_code.ma')
    case CountryCode.MZ:
      return t('country_code.mz')
    case CountryCode.MM:
      return t('country_code.mm')
    case CountryCode.NA:
      return t('country_code.na')
    case CountryCode.NR:
      return t('country_code.nr')
    case CountryCode.NP:
      return t('country_code.np')
    case CountryCode.NL:
      return t('country_code.nl')
    case CountryCode.NC:
      return t('country_code.nc')
    case CountryCode.NZ:
      return t('country_code.nz')
    case CountryCode.NI:
      return t('country_code.ni')
    case CountryCode.NE:
      return t('country_code.ne')
    case CountryCode.NG:
      return t('country_code.ng')
    case CountryCode.NU:
      return t('country_code.nu')
    case CountryCode.NF:
      return t('country_code.nf')
    case CountryCode.MP:
      return t('country_code.mp')
    case CountryCode.NO:
      return t('country_code.no')
    case CountryCode.OM:
      return t('country_code.om')
    case CountryCode.PK:
      return t('country_code.pk')
    case CountryCode.PW:
      return t('country_code.pw')
    case CountryCode.PS:
      return t('country_code.ps')
    case CountryCode.PA:
      return t('country_code.pa')
    case CountryCode.PG:
      return t('country_code.pg')
    case CountryCode.PY:
      return t('country_code.py')
    case CountryCode.PE:
      return t('country_code.pe')
    case CountryCode.PH:
      return t('country_code.ph')
    case CountryCode.PN:
      return t('country_code.pn')
    case CountryCode.PL:
      return t('country_code.pl')
    case CountryCode.PT:
      return t('country_code.pt')
    case CountryCode.PR:
      return t('country_code.pr')
    case CountryCode.QA:
      return t('country_code.qa')
    case CountryCode.RE:
      return t('country_code.re')
    case CountryCode.RO:
      return t('country_code.ro')
    case CountryCode.RU:
      return t('country_code.ru')
    case CountryCode.RW:
      return t('country_code.rw')
    case CountryCode.BL:
      return t('country_code.bl')
    case CountryCode.SH:
      return t('country_code.sh')
    case CountryCode.KN:
      return t('country_code.kn')
    case CountryCode.LC:
      return t('country_code.lc')
    case CountryCode.MF:
      return t('country_code.mf')
    case CountryCode.PM:
      return t('country_code.pm')
    case CountryCode.VC:
      return t('country_code.vc')
    case CountryCode.WS:
      return t('country_code.ws')
    case CountryCode.SM:
      return t('country_code.sm')
    case CountryCode.ST:
      return t('country_code.st')
    case CountryCode.SA:
      return t('country_code.sa')
    case CountryCode.SN:
      return t('country_code.sn')
    case CountryCode.RS:
      return t('country_code.rs')
    case CountryCode.SC:
      return t('country_code.sc')
    case CountryCode.SL:
      return t('country_code.sl')
    case CountryCode.SG:
      return t('country_code.sg')
    case CountryCode.SX:
      return t('country_code.sx')
    case CountryCode.SK:
      return t('country_code.sk')
    case CountryCode.SI:
      return t('country_code.si')
    case CountryCode.SB:
      return t('country_code.sb')
    case CountryCode.SO:
      return t('country_code.so')
    case CountryCode.ZA:
      return t('country_code.za')
    case CountryCode.GS:
      return t('country_code.gs')
    case CountryCode.SS:
      return t('country_code.ss')
    case CountryCode.ES:
      return t('country_code.es')
    case CountryCode.LK:
      return t('country_code.lk')
    case CountryCode.SD:
      return t('country_code.sd')
    case CountryCode.SR:
      return t('country_code.sr')
    case CountryCode.SJ:
      return t('country_code.sj')
    case CountryCode.SE:
      return t('country_code.se')
    case CountryCode.CH:
      return t('country_code.ch')
    case CountryCode.SY:
      return t('country_code.sy')
    case CountryCode.TW:
      return t('country_code.tw')
    case CountryCode.TJ:
      return t('country_code.tj')
    case CountryCode.TZ:
      return t('country_code.tz')
    case CountryCode.TH:
      return t('country_code.th')
    case CountryCode.TL:
      return t('country_code.tl')
    case CountryCode.TG:
      return t('country_code.tg')
    case CountryCode.TK:
      return t('country_code.tk')
    case CountryCode.TO:
      return t('country_code.to')
    case CountryCode.TT:
      return t('country_code.tt')
    case CountryCode.TN:
      return t('country_code.tn')
    case CountryCode.TR:
      return t('country_code.tr')
    case CountryCode.TM:
      return t('country_code.tm')
    case CountryCode.TC:
      return t('country_code.tc')
    case CountryCode.TV:
      return t('country_code.tv')
    case CountryCode.UG:
      return t('country_code.ug')
    case CountryCode.UA:
      return t('country_code.ua')
    case CountryCode.AE:
      return t('country_code.ae')
    case CountryCode.GB:
      return t('country_code.gb')
    case CountryCode.US:
      return t('country_code.us')
    case CountryCode.UM:
      return t('country_code.um')
    case CountryCode.UY:
      return t('country_code.uy')
    case CountryCode.UZ:
      return t('country_code.uz')
    case CountryCode.VU:
      return t('country_code.vu')
    case CountryCode.VE:
      return t('country_code.ve')
    case CountryCode.VN:
      return t('country_code.vn')
    case CountryCode.VG:
      return t('country_code.vg')
    case CountryCode.VI:
      return t('country_code.vi')
    case CountryCode.WF:
      return t('country_code.wf')
    case CountryCode.EH:
      return t('country_code.eh')
    case CountryCode.YE:
      return t('country_code.ye')
    case CountryCode.ZM:
      return t('country_code.zm')
    case CountryCode.ZW:
      return t('country_code.zw')
    case CountryCode.XI:
      return t('country_code.xi')
    case CountryCode.XU:
      return t('country_code.xu')
    case CountryCode.X1:
      return t('country_code.x1')
    case CountryCode.X2:
      return t('country_code.x2')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing country code formatting: `' + code + '`')
      }
      return t('common.unknown')
  }
}

export function formatDispositionDateRule(rule: OffsetDisposition) {
  switch (rule) {
    case 'PeriodEnd':
      return t('company.disposition_date_rule.period_end')
    case 'NextMonday':
      return t('company.disposition_date_rule.next_monday')
    case 'NextTuesday':
      return t('company.disposition_date_rule.next_tuesday')
    case 'NextWednesday':
      return t('company.disposition_date_rule.next_wednesday')
    case 'NextThursday':
      return t('company.disposition_date_rule.next_thursday')
    case 'NextFriday':
      return t('company.disposition_date_rule.next_friday')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing disposition date rule formatting: `' + rule + '`')
      }
      return t('common.unknown')
  }
}

export function formatEmployeePayType(type: EmployeePayType) {
  switch (type) {
    case 'Salaried':
      return t('employee.employee_pay_type.salaried')
    case 'Hourly Paid':
      return t('employee.employee_pay_type.hourly_paid')
    case 'Commissioned':
      return t('employee.employee_pay_type.commissioned')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing employee pay type formatting: `' + type + '`')
      }
      return t('common.unknown')
  }
}

export function formatEmploymentType(type: EmploymentType) {
  switch (type) {
    case 'FullTime':
      return t('employee.employment_type.full_time')
    case 'PartTime':
      return t('employee.employment_type.part_time')
    case 'Fixed':
      return t('employee.employment_type.fixed')
    case 'Hourly':
      return t('employee.employment_type.hourly')
    case 'Commissioned':
      return t('employee.employment_type.commissioned')
    case 'Freelancer':
      return t('employee.employment_type.freelancer')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing employment type formatting: `' + type + '`')
      }
      return t('common.unknown')
  }
}

export function formatGender(gender: Gender) {
  switch (gender) {
    case Gender.FEMALE:
      return t('gender.female')
    case Gender.MALE:
      return t('gender.male')
    case Gender.UNKNOWN:
      return t('gender.unknown')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing gender formatting: `' + gender + '`')
      }
      return t('common.unknown')
  }
}

export function formatIncomeType(type: IncomeType) {
  switch (type) {
    case IncomeType.DK_SALARY_INCOME:
      return t('income_type.denmark.salary_income')
    case IncomeType.DK_B_INCOME:
      return t('income_type.denmark.b_income')
    case IncomeType.DK_RESEARCH_INCOME:
      return t('income_type.denmark.research_income')
    case IncomeType.DK_CONTRIBUTION_FREE:
      return t('income_type.denmark.contribution_free')
    case IncomeType.DK_CONTRIBUTION_FREE_AM:
      return t('income_type.denmark.contribution_free_am')
    case IncomeType.DK_CONTRIBUTION_FREE_ABROAD:
      return t('income_type.denmark.contribution_free_abroad')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing income type formatting: `' + type + '`')
      }
      return t('common.unknown')
  }
}

export function formatMfaChannel(channel: MfaChannel) {
  switch (channel) {
    case MfaChannel.SMS:
      return t('account.mfa.sms')
    case MfaChannel.TOTP:
      return t('account.mfa.totp')
    case MfaChannel.NONE:
      return t('account.mfa.none')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing mfa channel formatting: `' + channel + '`')
      }
      return t('common.unknown')
  }
}

export function formatNationalIDLabel(type: NationalIDType) {
  switch (type) {
    case 'DK CPR':
    case 'DK Foreign':
      return t('employee.national_id.cpr')
    case 'DK CVR':
      return t('employee.national_id.cvr')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing national ID label formatting: `' + type + '`')
      }
      return t('common.unknown')
  }
}

export function formatNationalIDType(type: NationalIDType) {
  switch (type) {
    case 'DK CPR':
      return t('employee.national_id.cpr')
    case 'DK CVR':
      return t('employee.national_id.cvr')
    case 'DK Foreign':
      return t('employee.national_id.cpr_foreign')
    case 'DK No CPR':
      return t('employee.national_id.no_cpr')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing national ID type formatting: `' + type + '`')
      }
      return t('common.unknown')
  }
}

export function formatNetsMessageType(type: NetsMessageType) {
  switch (type) {
    case 'DispositionChanged':
      return t('nets.message_type.disposition_change')
    case 'Stop':
      return t('nets.message_type.stop')
    case 'TransactionReceipt':
      return t('nets.message_type.transaction_receipt')
    case 'MaximumAmount':
      return t('nets.message_type.maximum_amount')
    case 'Comment':
      return t('nets.message_type.comment')
    case 'Info':
      return t('nets.message_type.info')
    case 'DoubleDelivery':
      return t('nets.message_type.double_delivery')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing NETS message type formatting: `' + type + '`')
      }
      return t('common.unknown')
  }
}

export function formatOneTimePayType(type: OneTimePayType) {
  switch (type) {
    case 'Anniversary Bonus':
      return t('one_time_pay.type.anniversary_bonus')
    case 'Bonus':
      return t('one_time_pay.type.bonus')
    case 'Bonus No Vacation':
      return t('one_time_pay.type.bonus_no_vacation')
    case 'Bonus No Pension':
      return t('one_time_pay.type.bonus_no_pension')
    case 'Bonus No Vacation and Pension':
      return t('one_time_pay.type.bonus_no_vacation_and_pension')
    case 'Compensation No AM':
      return t('one_time_pay.type.compensation_no_am')
    case 'Compensation':
      return t('one_time_pay.type.compensation')
    case 'Fritvalg':
      return t('one_time_pay.type.fritvalg')
    case 'Fritvalg Increase':
      return t('one_time_pay.type.fritvalg_increase')
    case 'Fritvalg Increase No Vacation':
      return t('one_time_pay.type.fritvalg_increase_no_vacation')
    case 'Fritvalg Increase No Pension':
      return t('one_time_pay.type.fritvalg_increase_no_pension')
    case 'Fritvalg Increase No Vacation and Pension':
      return t('one_time_pay.type.fritvalg_increase_no_vacation_and_pension')
    case 'G-Dage':
      return t('one_time_pay.type.g_dage')
    case 'Grant':
      return t('one_time_pay.type.grant')
    case 'Honorary Gift':
      return t('one_time_pay.type.honorary_gift')
    case 'Honorary Gift Benefit In Kind':
      return t('one_time_pay.type.honorary_gift_benefit_in_kind')
    case 'SH-Dage':
      return t('one_time_pay.type.sh_dage')
    case 'SH-Dage Increase':
      return t('one_time_pay.type.sh_dage_increase')
    case 'SH-Dage Increase No Vacation':
      return t('one_time_pay.type.sh_dage_increase_no_vacation')
    case 'SH-Dage Increase No Pension':
      return t('one_time_pay.type.sh_dage_increase_no_pension')
    case 'SH-Dage Increase No Vacation and Pension':
      return t('one_time_pay.type.sh_dage_increase_no_vacation_and_pension')
    case 'Car Allowance':
      return t('one_time_pay.type.car_allowance')
    case 'Gross Vacation Money':
      return t('one_time_pay.type.gross_vacation_money')
    case 'Pay Check Advanced':
      return t('one_time_pay.type.pay_check_advanced')
    case 'Travel Allowance':
      return t('one_time_pay.type.travel_allowance')
    case 'Reimbursement':
      return t('one_time_pay.type.reimbursement')
    case 'Free Text':
      return t('one_time_pay.type.free_text')
    case 'Gross Pay Reduction':
      return t('one_time_pay.type.gross_pay_reduction')
    case 'Gross Pay Reduction No Vacation':
      return t('one_time_pay.type.gross_pay_reduction_no_vacation')
    case 'Gross Pay Reduction No Pension':
      return t('one_time_pay.type.gross_pay_reduction_no_pension')
    case 'Gross Pay Reduction No Vacation and Pension':
      return t('one_time_pay.type.gross_pay_reduction_no_vacation_and_pension')
    case 'Free Phone':
      return t('one_time_pay.type.free_phone')
    case 'ATP':
      return t('one_time_pay.type.atp')
    case 'Paid Vacation':
      return t('one_time_pay.type.paid_vacation')
    case 'Benefit In Kind':
      return t('one_time_pay.type.benefit_in_kind')
    case 'Tax Free Income':
      return t('one_time_pay.type.tax_free_income')
    case 'Tax Free Income Abroad':
      return t('one_time_pay.type.tax_free_income_abroad')
    case 'Vacation Fund Payment':
      return t('one_time_pay.type.vacation_fund_payment')
    case 'Overtime Payout':
      return t('one_time_pay.type.overtime_payout')
    case 'Tax Deduction Modification Increase':
      return t('one_time_pay.type.tax_deduction_modification_increase')
    case 'Tax Deduction Modification Decrease':
      return t('one_time_pay.type.tax_deduction_modification_decrease')
    case 'Work Hours':
      return t('one_time_pay.type.work_hours')
    case 'Car Simple':
      return t('one_time_pay.type.car_simple')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing one time pay type formatting: `' + type + '`')
      }
      return t('common.unknown')
  }
}

export function formatOneTimePayCategory(category: OneTimePayCategory) {
  switch (category) {
    case 'DKArt-0000':
      return t('one_time_pay.category.type_0000')
    case 'DKArt-0050':
      return t('one_time_pay.category.type_0050')
    case 'DKArt-0051':
      return t('one_time_pay.category.type_0051')
    case 'DKArt-0061':
      return t('one_time_pay.category.type_0061')
    case 'DKArt-0079':
      return t('one_time_pay.category.type_0079')
    case 'DKArt-0082':
      return t('one_time_pay.category.type_0082')
    case 'DKArt-0097':
      return t('one_time_pay.category.type_0097')
    case 'DKArt-0099':
      return t('one_time_pay.category.type_0099')
    case 'DKArt-0100':
      return t('one_time_pay.category.type_0100')
    case 'DKArt-0103':
      return t('one_time_pay.category.type_0103')
    case 'DKArt-0104':
      return t('one_time_pay.category.type_0104')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing one time pay category formatting: `' + category + '`')
      }
      return t('common.unknown')
  }
}

export function formatPaymentMethod(method: PaymentMethod) {
  switch (method) {
    case 'NETS':
      return t('payment_method.nets')
    case 'Stripe':
      return t('payment_method.stripe')
    case 'Zimpler':
      return t('payment_method.zimpler')
    case 'Manual':
      return t('payment_method.manual')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing payment method formatting: `' + method + '`')
      }
      return t('common.unknown')
  }
}

export function formatPayRollRunApprovalType(type: PayRollRunApprovalType) {
  switch (type) {
    case 'Automatic':
      return t('pay_roll.approval_type.automatic')
    case 'Manual':
      return t('pay_roll.approval_type.manual')
    case 'No Approval':
      return t('pay_roll.approval_type.no_approval')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing pay roll run approval type formatting: `' + type + '`')
      }
      return t('common.unknown')
  }
}

export function formatUserPayRollStatus(userPayRollStatus: UserPayRollStatus) {
  switch (userPayRollStatus) {
    case 'Scheduled':
    case 'Awaiting':
      return t('pay_roll.status.awaiting')
    case 'Approved':
      return t('pay_roll.status.approved')
    case 'Tentative':
      return t('pay_roll.status.partial_approved')
    case 'Reviewed':
      return t('pay_roll.status.reviewed')
    case 'Cancelled':
    case 'Rejected':
      return t('pay_roll.status.cancelled')
    case 'Automatic Approval':
      return t('pay_roll.status.automatic_approval')
    case 'Awaiting Approval':
      return t('pay_roll.status.awaiting_approval')
    case 'Failed':
      return t('pay_roll.status.failed')
    case 'Success':
      return t('pay_roll.status.success')
    case 'No Approval':
      return t('pay_roll.status.will_complete')
    case 'Unknown':
    default:
      return t('common.unknown')
  }
}

export function formatPayRollStatus(payRoll: PayRoll) {
  return formatUserPayRollStatus(convertPayRollStatus(payRoll))
}

export function formatPayRollTaskType(type: PayRollTaskType) {
  switch (type) {
    case 'RunPayRoll':
      return t('pay_roll.task.type.run_pay_roll')
    case 'Transport':
      return t('pay_roll.task.type.transport')
    case 'EIndkomst':
      return t('pay_roll.task.type.e_indkomst')
    case 'EIndkomstReply':
      return t('pay_roll.task.type.e_indkomst_reply')
    case 'Accounting':
      return t('pay_roll.task.type.accounting')
    case 'ZeroTaxReport':
      return t('pay_roll.task.type.zero_tax_report')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing pay roll task type formatting: `' + type + '`')
      }
      return t('common.unknown')
  }
}

export function formatPensionCustomerIDType(type?: PensionCustomerIDType) {
  switch (type) {
    case 'NationalID':
      return t('pension.customer_type.national_id')
    case 'AgreementID':
      return t('pension.customer_type.agreement_id')
    case 'EmployerID':
      return t('pension.customer_type.employer_id')
    case 'MemberID':
      return t('pension.customer_type.member_id')
    case 'AccountID':
      return t('pension.customer_type.account_id')
    default:
      return t('pension.customer_type.number')
  }
}

export function formatPensionScheme(scheme: PensionScheme) {
  switch (scheme) {
    case PensionScheme.EMPLOYEE_PAID:
    case PensionScheme.EMPLOYEE_PAID_NET:
      return t('pension.scheme.employee')
    case PensionScheme.EMPLOYER_PAID:
    case PensionScheme.EMPLOYER_PAID_NET:
      return t('pension.scheme.employer')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing pension scheme formatting: `' + scheme + '`')
      }
      return t('common.unknown')
  }
}

export function formatPensionDefinition(pension: PensionDefinition, companies: PensionCompany[]) {
  if (pension.title) {
    return pension.title
  }
  let scheme = ''
  switch (pension.scheme) {
    case 'ATP':
      return t('pension_definition.scheme.atp')
    case 'EmployeePaid':
    case 'EmployeePaidNet':
      scheme = t('pension.scheme.employee')
      break
    case 'EmployerPaid':
    case 'EmployerPaidNet':
      scheme = t('pension.scheme.employer')
      break
  }
  const isNet = pension.scheme == 'EmployeePaidNet' || pension.scheme === 'EmployerPaidNet'
  if (!pension.pensionCompanyID) {
    let key = 'pension_definition.title.no_pension_company'
    if (isNet) {
      key += '.net'
    }
    return t(key, { scheme })
  } else {
    const companyName = companies.find((c) => c.id === pension.pensionCompanyID)?.name ?? ''
    let key = 'pension_definition.title.pension_company'
    if (isNet) {
      key += '.net'
    }
    return t(key, { scheme, name: companyName })
  }
}

export function formatPreferredTaxCardType(type: PreferredTaxCardType) {
  switch (type) {
    case PreferredTaxCardType.MAIN_CARD:
      return t('tax_card.preferred.main_card')
    case PreferredTaxCardType.SECONDARY_CARD:
      return t('tax_card.preferred.secondary_card')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing preferred tax card type formatting: `' + type + '`')
      }
      return t('common.unknown')
  }
}

export function formatSalaryCycle(frequency: SalaryFrequency, offset?: number | null) {
  switch (frequency) {
    case 'Monthly':
      if (offset) {
        return t('salary_cycle.frequency_per.monthly_offset', {
          from: formatDisplayNumber(offset + 1),
          from_ordinal_suffix: formatOrdinalSuffix(offset + 1),
          to: formatDisplayNumber(offset),
          to_ordinal_suffix: formatOrdinalSuffix(offset),
        })
      } else {
        return t('salary_cycle.frequency_per.monthly')
      }
    case 'BiWeekly':
      return t('salary_cycle.frequency_per.biweekly')
    case 'Weekly':
      return t('salary_cycle.frequency_per.weekly')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing salary cycle formatting: `' + frequency + '`')
      }
      return t('common.unknown')
  }
}

export function formatBiweeklySalaryCycle(salaryCycle: SalaryCycle, showStart = true) {
  switch (salaryCycle.id) {
    case '94d7bfaf-06a0-59bc-71f3-eb8657c53c69': {
      if (showStart) {
        const today = getDate()
        const nextPeriod = salaryCycle.salaryPeriods.find((period) => !isTimeBefore(getDate(period.start), today))
        if (nextPeriod) {
          return t('salary_cycle.biweekly.period_a_start', { start: formatShortDate(nextPeriod.start) })
        }
      }
      return t('salary_cycle.biweekly.period_a')
    }
    case 'd6919616-680b-5d5e-4a5f-df6c50c0b71c': {
      if (showStart) {
        const today = getDate()
        const nextPeriod = salaryCycle.salaryPeriods.find((period) => !isTimeBefore(getDate(period.start), today))
        if (nextPeriod) {
          return t('salary_cycle.biweekly.period_b_start', { start: formatShortDate(nextPeriod.start) })
        }
      }
      return t('salary_cycle.biweekly.period_b')
    }
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing salary cycle id formatting: `' + salaryCycle.id + '`')
      }
      return t('common.unknown')
  }
}

export function formatSalaryFrequency(frequency: SalaryFrequency) {
  switch (frequency) {
    case 'Monthly':
      return t('salary_cycle.frequency.monthly')
    case 'BiWeekly':
      return t('salary_cycle.frequency.biweekly')
    case 'Weekly':
      return t('salary_cycle.frequency.weekly')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing salary frequency formatting: `' + frequency + '`')
      }
      return t('common.unknown')
  }
}

export function formatSalaryPrepaid(prepaid: boolean) {
  return prepaid ? t('salary_cycle.paid.prepaid') : t('salary_cycle.paid.postpaid')
}

export function formatSalaryCycleDescription(type: SalaryFrequency, prepaid: boolean) {
  return formatSalaryCycle(type) + ', ' + formatSalaryPrepaid(prepaid).toLowerCase()
}

export function formatTaxCardRequestType(type: TaxCardRequestType) {
  switch (type) {
    case 'NewEmployee':
      return t('tax_card.request_type.new_employee')
    case 'ChangedEmploymentDetails':
      return t('tax_card.request_type.changed_employment_details')
    case 'ChangedEmploymentPeriod':
      return t('tax_card.request_type.changed_employment_period')
    case 'ChangedPreferredTaxCardType':
      return t('tax_card.request_type.changed_preferred_tax_card_type')
    case 'ReRequest':
      return t('tax_card.request_type.re_request')
    case 'Unsubscribe':
      return t('tax_card.request_type.unsubscribe')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing tax card request type formatting: `' + type + '`')
      }
      return t('common.unknown')
  }
}

export function formatTaxCardType(type: TaxCardType) {
  switch (type) {
    case 'Primary':
      return t('tax_card.type.primary')
    case 'Secondary':
      return t('tax_card.type.secondary')
    case 'Brutto':
      return t('tax_card.type.gross')
    case 'Free':
      return t('tax_card.type.free')
    case 'None':
      return t('tax_card.type.none')
    case 'PrimaryNoRetainment':
      return t('tax_card.type.primary_no_retainment')
    case 'SecondaryNoRetainment':
      return t('tax_card.type.secondary_no_retainment')
    case 'BruttoNoRetainment':
      return t('tax_card.type.gross_no_retainment')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing tax card type formatting: `' + type + '`')
      }
      return t('common.unknown')
  }
}

export function formatTimeRegistrationClass(type: TimeRegistrationClass) {
  switch (type) {
    case 'Hours':
      return t('time_registration.class.hours')
    case 'Leave':
      return t('time_registration.class.leave')
    case 'Flex':
      return t('time_registration.class.flex')
    case 'Overtime':
      return t('time_registration.class.overtime')
    case 'Project Hours':
      return t('time_registration.class.project_hours')
    case 'Work Hours':
      return t('time_registration.class.work_hours')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing time registration type formatting: `' + type + '`')
      }
      return t('common.unknown')
  }
}

export function formatTransferSettingIntegration(integration: TransferSettingIntegration) {
  switch (integration) {
    case TransferSettingIntegration.NETS:
      return t('transfer.setting_integration.nets')
    case TransferSettingIntegration.NONE:
      return t('transfer.setting_integration.manual')
    case TransferSettingIntegration.STRIPE:
      return t('transfer.setting_integration.stripe')
    case TransferSettingIntegration.ZIMPLER:
      return t('transfer.setting_integration.zimpler')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing transfer setting integration formatting: `' + integration + '`')
      }
      return t('common.unknown')
  }
}

export function formatTransferType(type: TransferType, destinationType: TransferDestinationType = 'None') {
  switch (type) {
    case 'Salary':
      return t('transfer.type.salary')
    case 'FreelanceFee':
      return t('transfer.type.freelance_fee')
    case 'Invoice':
      return t('transfer.type.invoice')
    case 'Pension':
      switch (destinationType) {
        case 'DK Account':
          return t('transfer.type.pension_dk_account')
        default:
          return t('transfer.type.pension')
      }
    case 'AM Pension':
    case 'DK Pension':
      return t('transfer.type.pension')
    case 'DK ASkat':
      return t('transfer.type.dk_a_tax')
    case 'DK AM-bidrag':
      return t('transfer.type.dk_am_contribution')
    case 'DK ATP':
      return t('transfer.type.dk_atp')
    case 'DK Feriepenge':
      return t('transfer.type.dk_vacation_fund')
    case 'DK Feriepenge Custom':
      return t('transfer.type.dk_vacation_fund_custom')
    case 'DK Vacation Special Supplement':
      return t('transfer.type.dk_vacation_fund_special_supplement')
    case 'DK FLF':
    case 'DK FLF Company':
      return t('transfer.type.dk_flf')
    case 'DK FLF Company Zero':
      return t('transfer.type.dk_flf_company_zero')
    case 'DK FLF DA Barsel':
      return t('transfer.type.dk_flf_da_barsel')
    case 'Immediate Pay':
      return t('transfer.type.immediate_pay')
    case 'DK Forenede Gruppeliv':
      return t('transfer.type.dk_forenede_gruppeliv')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing transfer setting type formatting: `' + type + '`')
      }
      return t('common.unknown')
  }
}

export function formatTransferDestinationType(type: TransferDestinationType) {
  switch (type) {
    case 'DK Account':
      return t('transfer.destination_type.dk_account')
    case 'DK NemKonto':
      return t('transfer.destination_type.dk_nemkonto')
    case 'DK NemKonto CVR':
      return t('transfer.destination_type.dk_nemkonto_cvr')
    case 'None':
      return t('transfer.destination_type.none')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing transfer destination type formatting: `' + type + '`')
      }
      return t('common.unknown')
  }
}

export function formatLeaveDuration(duration: LeaveDuration) {
  switch (duration) {
    case LeaveDuration.FULL_DAY:
      return t('time_registration.leave_duration.full_day')
    case LeaveDuration.THREE_QUARTERS_DAY:
      return t('time_registration.leave_duration.three_quarters_day')
    case LeaveDuration.HALF_DAY:
      return t('time_registration.leave_duration.half_day')
    case LeaveDuration.QUARTER_DAY:
      return t('time_registration.leave_duration.quarter_day')
    case LeaveDuration.OTHER:
      return t('time_registration.leave_duration.other')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing leave duration formatting: `' + duration + '`')
      }
      return t('common.unknown')
  }
}

export function formatLeaveTypeName(
  name: LeaveTypeName,
  plural = false,
  remunerationType: RemunerationType | undefined = undefined,
  includePrecision = false
) {
  switch (name) {
    case 'DenmarkVacationPaid':
      return t('leave.type.denmark_vacation_paid', { count: plural ? 2 : 1 })
    case 'DenmarkVacationPaidAdditional':
      return t('leave.type.denmark_vacation_paid_additional', { count: plural ? 2 : 1 })
    case 'DenmarkVacationNoPay':
      return t('leave.type.denmark_vacation_no_pay', { count: plural ? 2 : 1 })
    case 'DenmarkVacationAccrual':
      return t('leave.type.denmark_vacation_accrual', { count: plural ? 2 : 1 })
    case 'DenmarkVacationFund':
      return t('leave.type.denmark_vacation_fund', { count: plural ? 2 : 1 })
    case 'DenmarkVacationFundWithPension':
      return t('leave.type.denmark_vacation_fund_with_pension', { count: plural ? 2 : 1 })
    case 'DenmarkPersonalDay':
      if (includePrecision) {
        return t('leave.type.denmark_personal_day', { count: plural ? 2 : 1 })
      } else {
        return t('leave.type.denmark_personal', { count: plural ? 2 : 1 })
      }
    case 'DenmarkPersonalTimeAccrual':
      if (includePrecision) {
        return t('leave.type.denmark_personal_day', { count: plural ? 2 : 1 })
      } else {
        return t('leave.type.denmark_personal', { count: plural ? 2 : 1 })
      }
    case 'DenmarkOptionalVacation':
      if (includePrecision) {
        return t('leave.type.denmark_optional_vacation', { count: plural ? 2 : 1 })
      } else {
        return t('leave.type.denmark_extra_vacation', { count: plural ? 2 : 1 })
      }
    case 'DenmarkExtraVacationAccrual':
      if (includePrecision) {
        return t('leave.type.denmark_extra_vacation_accrual', { count: plural ? 2 : 1 })
      } else {
        return t('leave.type.denmark_extra_vacation', { count: plural ? 2 : 1 })
      }
    case 'DenmarkSickDayPaid':
      if (remunerationType === RemunerationType.SALARIED) {
        return t('leave.type.denmark_sick_day_paid_salaried', { count: plural ? 2 : 1 })
      } else {
        return t('leave.type.denmark_sick_day_paid', { count: plural ? 2 : 1 })
      }
    case 'DenmarkUnpaidDayOff':
      return t('leave.type.denmark_unpaid_day_off', { count: plural ? 2 : 1 })
    case 'DenmarkDayOff':
      if (remunerationType === RemunerationType.SALARIED) {
        return t('leave.type.denmark_day_off_salaried', { count: plural ? 2 : 1 })
      } else {
        return t('leave.type.denmark_day_off', { count: plural ? 2 : 1 })
      }
    case 'DenmarkFlexTime':
      return t('leave.type.denmark_flex_time', { count: plural ? 2 : 1 })
    case 'DenmarkOvertime':
      return t('leave.type.denmark_overtime', { count: plural ? 2 : 1 })
    case 'DenmarkRemoteWorkDay':
      return t('leave.type.denmark_remote_work', { count: plural ? 2 : 1 })
    case 'DenmarkLeaveDay':
      return t('leave.type.denmark_leave_day', { count: plural ? 2 : 1 })
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing leave type name formatting: `' + name + '`')
      }
      return t('common.unknown')
  }
}

export function formatUserPermission(permission: UserPermission) {
  switch (permission) {
    case 'Admin':
      return t('user.permission.admin')
    case 'ApprovePayRoll':
      return t('user.permission.approve_pay_roll')
    case 'ReviewPayRoll':
      return t('user.permission.review_pay_roll')
    case 'Manager':
      return t('user.permission.manager')
    case 'ApproveOnly':
      return t('user.permission.approve_only')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing user permission formatting: `' + permission + '`')
      }
      return t('common.unknown')
  }
}

export function formatDepartmentPermission(permission: DepartmentPermission) {
  switch (permission) {
    case 'SeeSalaryRates':
      return t('user.department_permission.see_salary_rates')
    case 'ApproveObjects':
      return t('user.department_permission.approve_objects')
    case 'EditObjects':
      return t('user.department_permission.edit_objects')
    case 'HireFire':
      return t('user.department_permission.hire_fire')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing department permission formatting: `' + permission + '`')
      }
      return t('common.unknown')
  }
}

export function formatUserType(type: UserCompanyType) {
  switch (type) {
    case UserCompanyType.BUSINESS:
      return t('user.company_type.business')
    case UserCompanyType.ACCOUNTANT:
      return t('user.company_type.accountant')
    case UserCompanyType.INTERNAL_SALARY_ADMINISTRATOR:
      return t('user.company_type.internal_salary_administrator')
    case UserCompanyType.EXTERNAL_SALARY_ADMINISTRATOR:
      return t('user.company_type.external_salary_administrator')
    case UserCompanyType.BOOK_KEEPER:
      return t('user.company_type.book_keeper')
    case UserCompanyType.DEPARTMENT_MANAGER:
      return t('user.company_type.department_manager')
    case UserCompanyType.APPROVE_ONLY:
      return t('user.company_type.approve_only')
    case UserCompanyType.READ_ONLY:
      return t('user.company_type.read_only')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing user type formatting: `' + type + '`')
      }
      return t('common.unknown')
  }
}

export function formatFamilyLeaveFund(familyLeaveFund: FamilyLeaveFund) {
  switch (familyLeaveFund) {
    case FamilyLeaveFund.BARSEL_DK:
      return t('family_leave.fund.barsel_dk')
    case FamilyLeaveFund.BARSEL_DK_PARTIAL:
      return t('family_leave.fund.barsel_dk_partial')
    case FamilyLeaveFund.DA_BARSEL:
      return t('family_leave.fund.da_barsel')
    case FamilyLeaveFund.OTHER:
      return t('family_leave.fund.other')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing family leave fund formatting: `' + familyLeaveFund + '`')
      }
      return t('common.unknown')
  }
}

export function formatSupplementTypeName(name: SupplementTypeName) {
  if (name.match(/.*Fritvalg.*/)) {
    return t('supplement.type.short_name.denmark_fritvalg')
  }
  if (name.match(/.*SHDage.*/)) {
    return t('supplement.type.short_name.denmark_sh_dage')
  }
  switch (name) {
    case 'DenmarkVacationPaidSupplement':
    case 'DenmarkVacationSupplement':
      return t('supplement.type.denmark_vacation_supplement')
    case 'DenmarkGreatPrayerDaySupplement':
      return t('supplement.type.denmark_great_prayer_day_supplement')
    case 'DenmarkVacationSupplementSpecial':
      return t('supplement.type.denmark_vacation_supplement_special')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Unknown supplement type: `' + name + '`')
      }
      return t('common.unknown')
  }
}

type supplementTypeNameFormatOptions = {
  skipDirectPay?: boolean
  skipAmount?: boolean
}

export function formatSupplementTypeFullName(name: SupplementTypeName, options?: supplementTypeNameFormatOptions) {
  if (options?.skipDirectPay) {
    name = name.replace(/DirectPay/, '') as SupplementTypeName
  }
  if (options?.skipAmount) {
    name = name.replace(/Amount/, '') as SupplementTypeName
  }
  switch (name) {
    case 'DenmarkSHDage':
      return t('supplement.type.denmark_sh_dage')
    case 'DenmarkSHDageDirectPay':
      return t('supplement.type.denmark_sh_dage_direct_pay')
    case 'DenmarkSHDageAmount':
      return t('supplement.type.denmark_sh_dage_amount')
    case 'DenmarkSHDageAmountDirectPay':
      return t('supplement.type.denmark_sh_dage_amount_direct_pay')
    case 'DenmarkSHDageNoPension':
      return t('supplement.type.denmark_sh_dage_no_pension')
    case 'DenmarkSHDageNoPensionDirectPay':
      return t('supplement.type.denmark_sh_dage_no_pension_direct_pay')
    case 'DenmarkSHDageNoPensionAmount':
      return t('supplement.type.denmark_sh_dage_no_pension_amount')
    case 'DenmarkSHDageNoPensionAmountDirectPay':
      return t('supplement.type.denmark_sh_dage_no_pension_amount_direct_pay')
    case 'DenmarkSHDageWithVacation':
      return t('supplement.type.denmark_sh_dage_with_vacation')
    case 'DenmarkSHDageWithVacationDirectPay':
      return t('supplement.type.denmark_sh_dage_with_vacation_direct_pay')
    case 'DenmarkSHDageWithVacationAmount':
      return t('supplement.type.denmark_sh_dage_with_vacation_amount')
    case 'DenmarkSHDageWithVacationAmountDirectPay':
      return t('supplement.type.denmark_sh_dage_with_vacation_amount_direct_pay')
    case 'DenmarkSHDageWithVacationNoPension':
      return t('supplement.type.denmark_sh_dage_with_vacation_no_pension')
    case 'DenmarkSHDageWithVacationNoPensionDirectPay':
      return t('supplement.type.denmark_sh_dage_with_vacation_no_pension_direct_pay')
    case 'DenmarkSHDageWithVacationNoPensionAmount':
      return t('supplement.type.denmark_sh_dage_with_vacation_no_pension_amount')
    case 'DenmarkSHDageWithVacationNoPensionAmountDirectPay':
      return t('supplement.type.denmark_sh_dage_with_vacation_no_pension_amount_direct_pay')
    case 'DenmarkFritvalg':
      return t('supplement.type.denmark_fritvalg')
    case 'DenmarkFritvalgDirectPay':
      return t('supplement.type.denmark_fritvalg_direct_pay')
    case 'DenmarkFritvalgAmount':
      return t('supplement.type.denmark_fritvalg_amount')
    case 'DenmarkFritvalgAmountDirectPay':
      return t('supplement.type.denmark_fritvalg_amount_direct_pay')
    case 'DenmarkFritvalgNoPension':
      return t('supplement.type.denmark_fritvalg_no_pension')
    case 'DenmarkFritvalgNoPensionDirectPay':
      return t('supplement.type.denmark_fritvalg_no_pension_direct_pay')
    case 'DenmarkFritvalgNoPensionAmount':
      return t('supplement.type.denmark_fritvalg_no_pension_amount')
    case 'DenmarkFritvalgNoPensionAmountDirectPay':
      return t('supplement.type.denmark_fritvalg_no_pension_amount_direct_pay')
    case 'DenmarkFritvalgWithVacation':
      return t('supplement.type.denmark_fritvalg_with_vacation')
    case 'DenmarkFritvalgWithVacationDirectPay':
      return t('supplement.type.denmark_fritvalg_with_vacation_direct_pay')
    case 'DenmarkFritvalgWithVacationAmount':
      return t('supplement.type.denmark_fritvalg_with_vacation_amount')
    case 'DenmarkFritvalgWithVacationAmountDirectPay':
      return t('supplement.type.denmark_fritvalg_with_vacation_amount_direct_pay')
    case 'DenmarkFritvalgWithVacationNoPension':
      return t('supplement.type.denmark_fritvalg_with_vacation_no_pension')
    case 'DenmarkFritvalgWithVacationNoPensionDirectPay':
      return t('supplement.type.denmark_fritvalg_with_vacation_no_pension_direct_pay')
    case 'DenmarkFritvalgWithVacationNoPensionAmount':
      return t('supplement.type.denmark_fritvalg_with_vacation_no_pension_amount')
    case 'DenmarkFritvalgWithVacationNoPensionAmountDirectPay':
      return t('supplement.type.denmark_fritvalg_with_vacation_no_pension_amount_direct_pay')
    case 'DenmarkVacationPaidSupplement':
    case 'DenmarkVacationSupplement':
      return t('supplement.type.denmark_vacation_supplement')
    case 'DenmarkGreatPrayerDaySupplement':
      return t('supplement.type.denmark_great_prayer_day_supplement')
    case 'DenmarkVacationSupplementSpecial':
      return t('supplement.type.denmark_vacation_supplement_special')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Unknown supplement type: `' + name + '`')
      }
      return t('common.unknown')
  }
}

export function formatLeaveUnit(unit: LeaveUnit, plural = true) {
  switch (unit) {
    case 'Days':
      return t('unit.day', { count: plural ? 2 : 1 })
    case 'Hours':
      return t('unit.hour', { count: plural ? 2 : 1 })
    case 'Minutes':
      return t('unit.minute', { count: plural ? 2 : 1 })
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Unknown unit: `' + unit + '`')
      }
      return ''
  }
}

export function formatLanguage(language: Language) {
  switch (language) {
    case Language.DANISH:
      return t('common.language.da')
    case Language.ENGLISH:
      return t('common.language.en')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Unknown language: `' + language + '`')
      }
      return ''
  }
}

export function formatLeaveSubType(leaveSubType: LeaveSubTypeName) {
  switch (leaveSubType) {
    case "Child's first sick day":
      return t('leave.sub_type.child_s_first_sick_day')
    case "Child's second sick day":
      return t('leave.sub_type.child_s_second_sick_day')
    case 'Anniversary':
      return t('leave.sub_type.anniversary')
    case 'Moving day':
      return t('leave.sub_type.moving_day')
    case 'Mourning day':
      return t('leave.sub_type.mourning_day')
    case 'Time off in lieu':
      return t('leave.sub_type.time_off_in_lieu')
    case 'COVID-19':
      return t('leave.sub_type.covid_19')
    case 'Parental leave':
      return t('leave.sub_type.parental_leave')
    case 'Paragraph 56':
      return t('leave.sub_type.paragraph_56')
    case 'Course':
      return t('leave.sub_type.course')
    case 'Parental leave last 4 weeks before term':
      return t('leave.sub_type.parental_leave_last_4_weeks_before_term')
    case 'Parental leave first 2 weeks':
      return t('leave.sub_type.parental_leave_first_2_weeks')
    case 'Parental leave after 2 weeks':
      return t('leave.sub_type.parental_leave_after_2_weeks')
    case 'Parental leave sickness':
      return t('leave.sub_type.parental_leave_sickness')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Unknown leave sub type: `' + leaveSubType + '`')
      }
      return ''
  }
}

export function formatCompanyGroupUserType(userType: GroupUserType) {
  switch (userType) {
    case 'Admin':
      return t('company_group.user_type.admin')
    case 'Regular':
      return t('company_group.user_type.regular')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Unknown company group user type: `' + userType + '`')
      }
      return ''
  }
}

export function formatAsynchronousTaskStatus(taskStatus: AsynchronousTaskStatus) {
  switch (taskStatus) {
    case 'Pending':
      return t('asynchronous_task.status.pending')
    case 'Executing':
      return t('asynchronous_task.status.executing')
    case 'Success':
      return t('asynchronous_task.status.success')
    case 'Failed':
      return t('asynchronous_task.status.failed')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Unknown asynchronous task status: `' + taskStatus + '`')
      }
      return t('common.unknown')
  }
}

export function formatNameVacationDay(namedDay: VacationNamedDay) {
  switch (namedDay) {
    case 'Palm Sunday':
      return t('calendar.named_holiday.palm_sunday')
    case 'Maundy Thursday':
      return t('calendar.named_holiday.maundy_thursday')
    case 'Good Friday':
      return t('calendar.named_holiday.good_friday')
    case 'Easter Sunday':
      return t('calendar.named_holiday.easter_sunday')
    case 'Easter Monday':
      return t('calendar.named_holiday.easter_monday')
    case 'Great Prayer Day':
      return t('calendar.named_holiday.great_prayer_day')
    case 'Christ Ascension':
      return t('calendar.named_holiday.christ_ascension')
    case 'The Day After Christ Ascension':
      return t('calendar.named_holiday.day_after_christ_ascension')
    case 'First Day Of Pentecost':
      return t('calendar.named_holiday.first_day_of_pentecost')
    case 'Whit Monday':
      return t('calendar.named_holiday.whit_monday')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Unknown vacation day type: `' + namedDay + '`')
      }
      return t('common.unknown')
  }
}

export function formatBenefitType(type: BenefitType) {
  switch (type) {
    case 'Board and Lodging':
      return t('benefit.type.board_and_lodging')
    case 'Car Simple':
    case 'Car Managed':
      return t('benefit.type.car')
    case 'Health':
      return t('benefit.type.health')
    case 'Internet':
      return t('benefit.type.internet')
    case 'Lunch':
    case 'Lunch Daily':
      return t('benefit.type.lunch')
    case 'Permanent Residence':
      return t('benefit.type.permanent_residence')
    case 'Telephone':
      return t('benefit.type.telephone')
    case 'Fixed Disbursement':
      return t('benefit.type.fixed_disbursement')
    case 'Tax Reporting':
      return t('benefit.type.tax_reporting')
    case 'Tax Reporting No Threshold':
      return t('benefit.type.tax_reporting_no_threshold')
    case 'Gross Pay Reduction':
      return t('benefit.type.gross_pay_reduction')
    case 'Gross Pay Reduction No Vacation':
      return t('benefit.type.gross_pay_reduction_no_vacation')
    case 'Gross Pay Reduction No Pension':
      return t('benefit.type.gross_pay_reduction_no_pension')
    case 'Gross Pay Reduction No Vacation and Pension':
      return t('benefit.type.gross_pay_reduction_no_vacation_and_pension')
    case 'Net Pay Reduction':
      return t('benefit.type.net_pay_reduction')
    default:
      return t('benefit.type.other')
  }
}

export function formatBenefitDefinition(benefit: BenefitDefinition) {
  if (benefit.title) {
    return benefit.title
  }
  return formatBenefitType(benefit.type)
}

export function formatDeviationType(deviationType: DeviationType) {
  switch (deviationType) {
    case 'Hours':
      return t('deviation.type.hours')
    case 'Tax Card':
      return t('deviation.type.tax_card')
    case 'Gross Pay':
      return t('deviation.type.gross_pay')
    case 'Pay Check':
      return t('deviation.type.pay_check')
    case 'Gross Pay Reduction':
      return t('deviation.type.gross_pay_reduction')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing deviation type formatting: `' + deviationType + '`')
      }
      return t('common.unknown')
  }
}

export function formatDocumentSignatureType(signatureType: DocumentSignatureType) {
  switch (signatureType) {
    case 'None':
      return t('signing_document.signature_type.none')
    case 'SMS':
      return t('signing_document.signature_type.sms')
    case 'Criipto':
      return t('signing_document.signature_type.criipto')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing document signature type formatting: `' + signatureType + '`')
      }
      return t('common.unknown')
  }
}

export function formatApiKeyScope(scope: ApiKeyScope) {
  switch (scope) {
    case 'ReadAll':
      return t('api_client.scope.read_all')
    case 'WriteAll':
      return t('api_client.scope.write_all')
    case 'ReadBaseData':
      return t('api_client.scope.read_base_data')
    case 'ReadEmployments':
      return t('api_client.scope.read_employments')
    case 'ReadLeave':
      return t('api_client.scope.read_leave')
    case 'ReadPersonalData':
      return t('api_client.scope.read_personal_data')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing scope formatting: `' + scope + '`')
      }
      return t('common.unknown')
  }
}

export function formatAsynchronousScheduleType(type: AsynchronousScheduleType) {
  switch (type) {
    case 'LeaveRegistrationExport':
      return t('asynchronous_task.schedule_type.leave_registration_export')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing asynchronous schedule type formatting: `' + type + '`')
      }
      return t('common.unknown')
  }
}

export function formatSourceType(source: SourceType) {
  switch (source) {
    case 'System':
      return t('source_type.system')
    case 'Business':
      return t('source_type.business')
    case 'Employee':
      return t('source_type.employee')
    case 'Support':
      return t('source_type.support')
    case 'API Client':
      return t('source_type.api_client')
    case 'External Import':
      return t('source_type.external_import')
    case 'File Import':
      return t('source_type.file_import')
    default:
      if (process.env.NODE_ENV !== 'test') {
        logWarning('Missing source type formatting: `' + source + '`')
      }
      return t('common.unknown')
  }
}
