import React, { ReactElement, useEffect } from 'react'

import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import {
  addAsynchronousSchedule,
  deleteAsynchronousSchedule,
  getAsynchronousSchedules,
  updateAsynchronousSchedule,
} from '../actions/asynchronous-schedules'
import {
  getAsynchronousTask,
  getAsynchronousTasks,
  saveLeaveRegistrationExport,
  startCarAllowanceImport,
  startEmployeeImport,
  startLeaveRegistrationExport,
  startLeaveRegistrationImport,
  startPayRollImport,
  startReimbursementImport,
  storeStagedImportData,
} from '../actions/asynchronous-tasks'
import { getAvailableDataIntegrations } from '../actions/available-data-integrations'
import { getContracts } from '../actions/contracts'
import { deleteCompanyDataIntegration, getCompanyDataIntegration } from '../actions/data-integration'
import { getPayRolls } from '../actions/pay-rolls'
import { getSalaryCycles } from '../actions/salary-cycles'
import { getSalaryTypes } from '../actions/salary-types'
import { getTimeRegistrations } from '../actions/time-registrations'
import DataIntegrationComponent from '../components/data-integration/DataIntegration'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import LoadingOverlay from '../components/widgets/LoadingOverlay'
import { AsynchronousScheduleCreationFields } from '../model/asynchronousSchedule'
import AsynchronousTask from '../model/asynchronousTask'
import { ExternalPayRollImportMode } from '../model/dataIntegration'
import { DateFormat } from '../model/types'
import { AlertReducer } from '../reducers/alerts'
import { AsynchronousScheduleReducer } from '../reducers/asynchronousSchedules'
import { AsynchronousTaskReducer } from '../reducers/asynchronousTasks'
import { AvailableDataIntegrationReducer } from '../reducers/availableDataIntegrations'
import { CompanyReducer } from '../reducers/companies'
import { CompanyDataIntegrationReducer } from '../reducers/companyDataIntegration'
import { ContractReducer } from '../reducers/contracts'
import { EmployeeReducer } from '../reducers/employees'
import { LeaveTypeReducer } from '../reducers/leaveTypes'
import { PayRollReducer } from '../reducers/payRolls'
import { SalaryCycleReducer } from '../reducers/salaryCycles'
import { SalaryTypeReducer } from '../reducers/salaryTypes'
import { TimeRegistrationReducer } from '../reducers/timeRegistrations'
import PreferredTaxCardType from '../types/preferred-tax-card-type'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps } from '../utils/route-utils'
import IntegrationsLayout from './layouts/IntegrationsLayout'

type Reducers = {
  alerts: AlertReducer
  asynchronousTasks: AsynchronousTaskReducer
  asynchronousSchedules: AsynchronousScheduleReducer
  companies: CompanyReducer
  contracts: ContractReducer
  availableDataIntegrations: AvailableDataIntegrationReducer
  companyDataIntegration: CompanyDataIntegrationReducer
  employees: EmployeeReducer
  payRolls: PayRollReducer
  salaryTypes: SalaryTypeReducer
  salaryCycles: SalaryCycleReducer
  leaveTypes: LeaveTypeReducer
  timeRegistrations: TimeRegistrationReducer
}

type Actions = {
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  getAvailableDataIntegrations: () => void
  getCompanyDataIntegration: () => void
  deleteCompanyDataIntegration: () => void
  getPayRolls: () => void
  getSalaryTypes: () => void
  startPayRollImport: (
    companyID: string,
    salaryPeriodID: string,
    mode: ExternalPayRollImportMode
  ) => Promise<AsynchronousTask | void>
  startLeaveRegistrationImport: (companyID: string, salaryPeriodID: string) => Promise<AsynchronousTask | void>
  startLeaveRegistrationExport: (companyID: string) => Promise<AsynchronousTask | void>
  saveLeaveRegistrationExport: (
    companyID: string,
    asynchronousTaskID: string,
    timeRegistrationIDs: string[]
  ) => Promise<AsynchronousTask | void>
  startEmployeeImport: (companyID: string, preferredTaxCard: PreferredTaxCardType) => Promise<AsynchronousTask | void>
  startReimbursementImport: (companyID: string, salaryPeriodID: string) => Promise<AsynchronousTask | void>
  startCarAllowanceImport: (companyID: string, salaryPeriodID: string) => Promise<AsynchronousTask | void>
  storeStagedImportData: (asynchronousTaskID: string, employeesToImport?: string[]) => Promise<AsynchronousTask | void>
  getAsynchronousTasks: (companyID: string) => void
  getAsynchronousTask: (asynchronousTaskID: string) => void
  getTimeRegistrations: (
    companyID: string | undefined,
    employeeID: string | undefined,
    payRollID: string | undefined,
    from: DateFormat,
    to: DateFormat
  ) => void
  getContracts: () => void
  getSalaryCycles: () => void
  getAsynchronousSchedules: (companyID: string) => void
  addAsynchronousSchedule: (schedule: AsynchronousScheduleCreationFields) => void
  updateAsynchronousSchedule: (companyID: string, scheduleID: string, cron: string) => void
  deleteAsynchronousSchedule: (companyID: string, scheduleID: string) => void
}

function DataIntegration(props: Reducers & Actions & RouteProps): ReactElement | null {
  const {
    companies,
    availableDataIntegrations,
    companyDataIntegration,
    payRolls,
    salaryTypes,
    asynchronousTasks,
    asynchronousSchedules,
    salaryCycles,
  } = props
  const {
    getAvailableDataIntegrations,
    getCompanyDataIntegration,
    getPayRolls,
    getSalaryTypes,
    getAsynchronousTasks,
    getAsynchronousSchedules,
    getSalaryCycles,
  } = props
  const company = companies.company
  useEffect(() => {
    if (!company) {
      return
    }

    if (!availableDataIntegrations.loaded && !availableDataIntegrations.loading) {
      getAvailableDataIntegrations()
    }
    if (!companyDataIntegration.loading && !companyDataIntegration.loaded) {
      getCompanyDataIntegration()
    }
    if (!payRolls.loading && !payRolls.loaded) {
      getPayRolls()
    }
    if (!salaryTypes.loading && !salaryTypes.loaded) {
      getSalaryTypes()
    }
    if (!asynchronousTasks.loading && !asynchronousTasks.loaded) {
      getAsynchronousTasks(company.id)
    }
    if (!asynchronousSchedules.loading && !asynchronousSchedules.loaded) {
      getAsynchronousSchedules(company.id)
    }
    if (!salaryCycles.loading && !salaryCycles.loaded) {
      getSalaryCycles()
    }
  })

  useEffect(() => {
    if (!company) {
      jsBrowserHistory.push('/')
    }
  })

  if (!company) {
    return null
  }

  if (!availableDataIntegrations.loaded || !companyDataIntegration.loaded) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay />
      </div>
    )
  }

  return (
    <IntegrationsLayout location={props.location}>
      <DataIntegrationComponent
        alerts={props.alerts}
        asynchronousTasks={props.asynchronousTasks}
        asynchronousSchedules={props.asynchronousSchedules}
        company={company}
        contracts={props.contracts}
        availableDataIntegrations={props.availableDataIntegrations.availableDataIntegrations}
        companyDataIntegration={props.companyDataIntegration}
        employees={props.employees.employees}
        payRolls={props.payRolls.payRolls}
        salaryTypes={props.salaryTypes.salaryTypes}
        salaryCycles={props.salaryCycles.salaryCycles}
        leaveTypes={props.leaveTypes.leaveTypes}
        timeRegistrations={props.timeRegistrations}
        addAlert={props.addAlert}
        removeAlert={props.removeAlert}
        deleteCompanyDataIntegration={props.deleteCompanyDataIntegration}
        storeStagedImportData={props.storeStagedImportData}
        startPayRollImport={props.startPayRollImport}
        startLeaveRegistrationImport={props.startLeaveRegistrationImport}
        startLeaveRegistrationExport={props.startLeaveRegistrationExport}
        saveLeaveRegistrationExport={props.saveLeaveRegistrationExport}
        startEmployeeImport={props.startEmployeeImport}
        startReimbursementImport={props.startReimbursementImport}
        startCarAllowanceImport={props.startCarAllowanceImport}
        getAsynchronousTask={props.getAsynchronousTask}
        getCompanyDataIntegration={props.getCompanyDataIntegration}
        getTimeRegistrations={props.getTimeRegistrations}
        getContracts={props.getContracts}
        addAsynchronousSchedule={props.addAsynchronousSchedule}
        updateAsynchronousSchedule={props.updateAsynchronousSchedule}
        deleteAsynchronousSchedule={props.deleteAsynchronousSchedule}
      />
    </IntegrationsLayout>
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    alerts: state.alerts,
    companies: state.companies,
    contracts: state.contracts,
    availableDataIntegrations: state.availableDataIntegrations,
    companyDataIntegration: state.companyDataIntegration,
    employees: state.employees,
    payRolls: state.payRolls,
    salaryTypes: state.salaryTypes,
    salaryCycles: state.salaryCycles,
    leaveTypes: state.leaveTypes,
    asynchronousTasks: state.asynchronousTasks,
    asynchronousSchedules: state.asynchronousSchedules,
    timeRegistrations: state.timeRegistrations,
  }),
  {
    addAlert: addAlert,
    removeAlert: removeAlert,
    getAvailableDataIntegrations: getAvailableDataIntegrations,
    getCompanyDataIntegration: getCompanyDataIntegration,
    deleteCompanyDataIntegration: deleteCompanyDataIntegration,
    getPayRolls: getPayRolls,
    getSalaryTypes: getSalaryTypes,
    getSalaryCycles: getSalaryCycles,
    startPayRollImport: startPayRollImport,
    storeStagedImportData: storeStagedImportData,
    startLeaveRegistrationImport: startLeaveRegistrationImport,
    startLeaveRegistrationExport: startLeaveRegistrationExport,
    saveLeaveRegistrationExport: saveLeaveRegistrationExport,
    startEmployeeImport: startEmployeeImport,
    startReimbursementImport: startReimbursementImport,
    startCarAllowanceImport: startCarAllowanceImport,
    getAsynchronousTasks: getAsynchronousTasks,
    getAsynchronousTask: getAsynchronousTask,
    getTimeRegistrations: getTimeRegistrations,
    getContracts: getContracts,
    getAsynchronousSchedules: getAsynchronousSchedules,
    addAsynchronousSchedule: addAsynchronousSchedule,
    updateAsynchronousSchedule: updateAsynchronousSchedule,
    deleteAsynchronousSchedule: deleteAsynchronousSchedule,
  }
)(DataIntegration)
