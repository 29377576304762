import React, { ReactElement } from 'react'

import { ApiKeyScope, ApiKeyScopes } from '../../model/apiKey'
import { ApiKeyReducer } from '../../reducers/apiKeys'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { formatApiKeyScope } from '../../utils/format-utils'
import { setByPath } from '../../utils/object-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Input from '../elements/input'
import SwitchWrapper from '../form-elements/SwitchWrapper'
import LoadingOverlay from '../widgets/LoadingOverlay'

type Props = {
  apiKeyID?: string
  apiKeys: ApiKeyReducer
}

type Fields = {
  description?: string
} & Record<`toggle${ApiKeyScope}`, boolean>

export type ApiKeyResult = {
  description: string
  scopes: ApiKeyScope[]
}

function ApiKeyEditForm(props: Props & FormComponentProps<Fields, ApiKeyResult>): ReactElement | null {
  const { decorateField } = props

  return (
    <div>
      {props.getFormError()}
      <Row>
        <Col span={24}>
          {decorateField('description', {
            placeholder: t('api_key.edit.form.description'),
            validate: (val) => (!val ? t('api_key.edit.form.validation.description_required') : null),
          })(<Input style={{ width: '100%' }} />)}
        </Col>
      </Row>
      <Row>
        {ApiKeyScopes.map((scope, i) => (
          <Col span={12} key={`scope-${i}`}>
            <SwitchWrapper<Fields> id={`toggle${scope}`} decorateField={decorateField}>
              {formatApiKeyScope(scope)}
            </SwitchWrapper>
          </Col>
        ))}
      </Row>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="large" type="secondary">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
      {props.apiKeys.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, ApiKeyResult>({
  mapPropsToFields: (props) => {
    const fields: Fields = {
      toggleReadAll: true,
      toggleWriteAll: true,
      toggleReadBaseData: false,
      toggleReadEmployments: false,
      toggleReadLeave: false,
      toggleReadPersonalData: false,
    }
    const apiKey = props.apiKeys.apiKeys.find((apiKey) => apiKey.id === props.apiKeyID)
    if (apiKey) {
      fields.description = apiKey.description
      fields.toggleReadAll = apiKey.scopes.some((scope) => scope === 'ReadAll')
      fields.toggleWriteAll = apiKey.scopes.some((scope) => scope === 'WriteAll')
      fields.toggleReadBaseData = apiKey.scopes.some((scope) => scope === 'ReadBaseData')
      fields.toggleReadEmployments = apiKey.scopes.some((scope) => scope === 'ReadEmployments')
      fields.toggleReadLeave = apiKey.scopes.some((scope) => scope === 'ReadLeave')
      fields.toggleReadPersonalData = apiKey.scopes.some((scope) => scope === 'ReadPersonalData')
    }
    return fields
  },
  onChange: (key, val) => {
    const values: Partial<Fields> = {}
    setByPath(values, key, val)
    if (key === 'toggleWriteAll' && val === true) {
      setByPath(values, 'toggleReadAll', true) // force read all to be true, when write all is true
    }
    return values
  },
  onSubmit: (values) => ({
    description: values.description!,
    scopes: ApiKeyScopes.filter((scope) => values[`toggle${scope}`]),
  }),
})(ApiKeyEditForm)
