import { List } from 'immutable'
import React, { ReactElement } from 'react'

import SalaryCycle from '../../model/salaryCycle'
import SalaryType, { TitleTranslations } from '../../model/salaryType'
import { EmployeePayType } from '../../utils/employee-utils'
import {
  decorateAnyFieldSignature,
  getAnyFieldErrorSignature,
  getAnyFieldValueSignature,
  getFieldValueSignature,
  setAnyFieldValueSignature,
  setFieldValueSignature,
} from '../../utils/form-utils'
import { formatSalaryCycle } from '../../utils/format-utils'
import { forceParseInputNumber } from '../../utils/number-utils'
import {
  getSalaryType,
  isSalarySupplement,
  listSalaryTypesWithNumberDiscriminator,
} from '../../utils/salary-type-utils'
import { t } from '../../utils/translation-utils'
import Form from '../antd/form'
import Select from '../antd/select'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Icon from '../elements/Icon'
import Input from '../elements/input'
import Subcard from '../elements/Subcard'
import Tooltip from '../elements/tooltip'

type SalaryRow = {
  salaryTypeID: string
  title?: string
  rate?: string
  quantity?: string
}

type PayFormFields = {
  remuneration: {
    salary: SalaryRow[]
  }
  salaryCycleID: string
  payType: EmployeePayType
}

type Props<Fields extends PayFormFields> = {
  decorateAnyField: decorateAnyFieldSignature<Fields>
  getFieldValue: getFieldValueSignature<Fields>
  getAnyFieldValue: getAnyFieldValueSignature
  setFieldValue: setFieldValueSignature<Fields>
  setAnyFieldValue: setAnyFieldValueSignature
  getAnyFieldError: getAnyFieldErrorSignature
  disableNewRows: boolean
  salaryCycles: List<SalaryCycle>
  salaryTypes: List<SalaryType>
}

export default function PayForm<Fields extends PayFormFields>(props: Props<Fields>): ReactElement | null {
  const add = () => {
    const { getFieldValue, setFieldValue, setAnyFieldValue } = props
    const remuneration = getFieldValue('remuneration')
    if (remuneration.salary.length === 0) {
      setFieldValue('payType', 'Salaried')
    }
    setAnyFieldValue(`remuneration.salary.${remuneration.salary.length}`, {})
  }
  const remove = (i: number) => {
    const { getFieldValue, setFieldValue, setAnyFieldValue } = props
    const remuneration = getFieldValue('remuneration')
    remuneration.salary.splice(i, 1)
    setAnyFieldValue('remuneration.salary', remuneration.salary)
    if (remuneration.salary.length === 0) {
      setFieldValue('payType', 'Commissioned')
    }
  }

  const move = (from: number, to: number) => {
    const remuneration = props.getFieldValue('remuneration')
    const salary = remuneration.salary
    ;[salary[to], salary[from]] = [salary[from], salary[to]]
    props.setAnyFieldValue('remuneration.salary', salary)
  }

  const getFrequency = (salaryTypes: SalaryType[], salaryTypeID: string, quantity: number, salaryCycleID: string) => {
    let frequency = t('employment_pay.edit.pay.frequency.hourly')
    const type = getSalaryType(salaryTypes, salaryTypeID)
    if (type && type.class !== 'Hourly' && type.class !== 'HourlyAggregate') {
      const salaryCycle = props.salaryCycles.find((item) => item.id === salaryCycleID)
      if (salaryCycle) {
        frequency = t(
          type.class === 'Supplement'
            ? 'employment_pay.edit.pay.frequency.supplement'
            : 'employment_pay.edit.pay.frequency.salary',
          {
            cycle: formatSalaryCycle(salaryCycle.frequency, salaryCycle.offset).toLowerCase(),
          }
        )
      }
    }
    if (type && type.class === 'SupplementVaried') {
      frequency = t('employment_pay.edit.pay.frequency.supplement_varied')
    }
    return frequency
  }

  const { decorateAnyField, getFieldValue, getAnyFieldValue, getAnyFieldError } = props

  const oneOf = (translations: TitleTranslations | undefined, title: string) =>
    translations && (translations.en === title || translations.da === title)

  const rows = getAnyFieldValue('remuneration.salary') as SalaryRow[]

  // this checks whether a salary type MUST be included or NOT
  // it returns undefined if it has no opinion
  const checkSalaryType = (salaryType: SalaryType): boolean | undefined => {
    if (getFieldValue('payType') !== 'Commissioned' && salaryType.class === 'Supplement') {
      return true
    }
    if (getFieldValue('payType') === 'Hourly Paid' && salaryType.class === 'HourlyAggregate') {
      return true
    }
    if (getFieldValue('payType') === 'Hourly Paid' && salaryType.class === 'Fixed') {
      return false
    }
    if (
      getFieldValue('payType') === 'Salaried' &&
      (salaryType.class === 'Hourly' || salaryType.class === 'HourlyAggregate')
    ) {
      return false
    }
    if (getFieldValue('payType') === 'Commissioned' && salaryType.class !== 'SupplementVaried') {
      return false
    }
    return undefined
  }

  const salaryTypes = props.salaryTypes
    .filter((salaryType) => {
      const check = checkSalaryType(salaryType)
      if (check !== undefined) {
        return check
      }
      return salaryType.active || rows.some((v) => v.salaryTypeID === salaryType.id)
    })
    .toArray()

  return (
    <div>
      <Subcard>
        {getFieldValue('payType') === 'Salaried' &&
          getFieldValue('remuneration').salary.some(
            (r) =>
              getSalaryType(props.salaryTypes.toArray(), r.salaryTypeID)?.name === 'Fixed' &&
              !!r.rate &&
              forceParseInputNumber(r.rate) <= 0
          ) && <p className="form-error">{t('employment_pay.edit.pay.salary_0_note')}</p>}
        <Row style={{ marginBottom: '-10px' }}>
          <Col span={10}>
            <label>{t('employment_pay.edit.pay.header.type')}</label>
          </Col>
          <Col span={3}>
            <label>{t('employment_pay.edit.pay.header.quantity')}</label>
          </Col>
          <Col span={11}>
            <label>{t('employment_pay.edit.pay.header.rate')}</label>
          </Col>
        </Row>
        {rows.map((salary, i) => {
          // we copy it to ensure we are not modifying the same array
          const tmpTypes = [...salaryTypes] // this list is already filtered
          // if the standard list does not contain this type, we add it manually
          if (!tmpTypes.some((t) => t.id === salary.salaryTypeID)) {
            const st = props.salaryTypes.find((st) => st.id === salary.salaryTypeID)
            if (st) {
              tmpTypes.push(st)
            }
          }
          const types = listSalaryTypesWithNumberDiscriminator(tmpTypes).sort((a, b) => {
            const diff = a.class.localeCompare(b.class)
            if (diff !== 0) {
              return diff
            }
            return a.title.localeCompare(b.title)
          })

          const isDefaultSalaryTypeTitle =
            !getAnyFieldValue(`remuneration.salary.${i}.title`) ||
            oneOf(
              types.find((type) => type.id === getAnyFieldValue(`remuneration.salary.${i}.salaryTypeID`))
                ?.titleTranslations,
              getAnyFieldValue(`remuneration.salary.${i}.title`) as string
            )
          const currentSalaryTypeID = getAnyFieldValue(`remuneration.salary.${i}.salaryTypeID`) as string
          let currentSalaryType
          if (currentSalaryTypeID) {
            currentSalaryType = props.salaryTypes.find((type) => type.id === currentSalaryTypeID)
          }
          return (
            <Form.Item
              key={i}
              validateStatus={
                getAnyFieldError(`remuneration.salary.${i}.salaryTypeID`) ||
                getAnyFieldError(`remuneration.salary.${i}.title`) ||
                getAnyFieldError(`remuneration.salary.${i}.rate`) ||
                getAnyFieldError(`remuneration.salary.${i}.quantity`)
                  ? 'error'
                  : 'success'
              }
            >
              <Row>
                <Col span={10}>
                  <Input.Group compact className="contracts-optional-description">
                    {decorateAnyField(`remuneration.salary.${i}.salaryTypeID`, {
                      placeholder: t('employment_pay.edit.pay.salary_type_id.placeholder'),
                      validate: (val) => (!val ? t('employment_pay.edit.pay.salary_type_id.required') : null),
                      skipWrapper: true,
                      skipLabel: true,
                    })(
                      <Select
                        dropdownMatchSelectWidth={false}
                        showSearch={true}
                        filterOption={(inputValue: string, option: ReactElement) => {
                          const item = option.props.children
                          return item.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                        }}
                      >
                        {types.map((type) => {
                          return (
                            <Select.Option key={type.id} value={type.id}>
                              {type.displayTitle}
                            </Select.Option>
                          )
                        })}
                      </Select>
                    )}
                    {decorateAnyField(`remuneration.salary.${i}.title`, {
                      placeholder: t('employment_pay.edit.pay.title'),
                      skipWrapper: true,
                      skipLabel: true,
                    })(<Input className={isDefaultSalaryTypeTitle ? '' : 'contracts-optional-description-active'} />)}
                  </Input.Group>
                </Col>
                <Col span={3}>
                  {currentSalaryType &&
                    isSalarySupplement(currentSalaryType) &&
                    decorateAnyField(`remuneration.salary.${i}.quantity`, {
                      placeholder: t('employment_pay.edit.pay.quantity'),
                      validate: (val) => {
                        if (val == null || val === '') {
                          return null
                        }
                        if (val < 0 || val === 0) {
                          return t('employment_pay.edit.pay.quantity.invalid')
                        }
                        return null
                      },
                      skipWrapper: true,
                      skipLabel: true,
                    })(<Input />)}
                </Col>
                <Col span={9}>
                  {decorateAnyField(`remuneration.salary.${i}.rate`, {
                    placeholder: t('employment_pay.edit.pay.rate'),
                    suffix: t('employment_pay.edit.pay.rate.suffix', {
                      frequency: getFrequency(
                        props.salaryTypes.toArray(),
                        getAnyFieldValue(`remuneration.salary.${i}.salaryTypeID`) as string,
                        forceParseInputNumber(getAnyFieldValue(`remuneration.salary.${i}.quantity`) as string),
                        getFieldValue('salaryCycleID')
                      ),
                    }),
                    validate: (val: string | undefined) => {
                      if (getFieldValue('payType') === 'Salaried') {
                        if (!val) {
                          return t('employment_pay.edit.pay.rate.required')
                        }
                        if (forceParseInputNumber(val) <= 0) {
                          return t('employment_pay.edit.pay.rate.salaried_must_be_more_than_0')
                        }
                      }
                      return null
                    },
                    skipWrapper: true,
                    skipLabel: true,
                  })(<Input />)}
                </Col>
                <Col span={1} className="contracts-move-row">
                  {i > 0 ? (
                    <Tooltip title={t('employment_pay.edit.pay.row_move_up')}>
                      <span onClick={() => move(i, i - 1)}>
                        <Icon type="arrow-up" color="lightgrey" />
                      </span>
                    </Tooltip>
                  ) : (
                    <span>&nbsp;</span>
                  )}
                  {i < rows.length - 1 && (
                    <Tooltip title={t('employment_pay.edit.pay.row_move_down')}>
                      <span onClick={() => move(i, i + 1)}>
                        <Icon type="arrow-down" color="lightgrey" />
                      </span>
                    </Tooltip>
                  )}
                </Col>
                <Col span={1} className="contracts-remove-row">
                  <span onClick={() => remove(i)}>
                    <Icon type="cross" color="grey" />
                  </span>
                </Col>
              </Row>
            </Form.Item>
          )
        })}
        {!props.disableNewRows && (
          <span className="contracts-add-row" onClick={add}>
            <Icon type="add" color="orange" /> {t('employment_pay.edit.pay.add_row')}
          </span>
        )}
      </Subcard>
    </div>
  )
}
