import React from 'react'

import ActionTypes from '../constants/action-types'
import Modal, { ModalOption, ModalType } from '../model/modal'
import { ModalAction } from '../reducers/modals'

export function addedModal(modal: Modal): ModalAction {
  return {
    type: ActionTypes.MODAL_ADDED,
    modal,
  }
}
export function removedModal(modalID: number): ModalAction {
  return {
    type: ActionTypes.MODAL_REMOVED,
    modalID,
  }
}

export function addModal(type: ModalType, options?: ModalOption) {
  return (dispatch: React.Dispatch<any>): void => {
    options = options || { closable: true, width: 720 }
    // eslint-disable-next-line no-prototype-builtins
    options.closable = options.hasOwnProperty('closable') ? options.closable : true
    // eslint-disable-next-line no-prototype-builtins
    options.width = options.hasOwnProperty('width') ? options.width : 720
    dispatch(addedModal({ type, options }))
  }
}

export function removeModal(id: number) {
  return (dispatch: React.Dispatch<any>): void => {
    dispatch(removedModal(id))
  }
}
