import React, { ReactElement, ReactNode } from 'react'

import {
  EmployeeImportFields,
  EmployeeImportMappingField,
  ExternalMappingFieldType,
  InternalMappingFieldType,
} from '../../api/data-integration'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { t, tx } from '../../utils/translation-utils'
import Select from '../antd/select'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Input from '../elements/input'

type Props = {
  displayName: string
  state: EmployeeImportFields
}

type Fields = Partial<Record<`field-${InternalMappingFieldType}`, string>> &
  Partial<Record<`value-${InternalMappingFieldType}`, string>>

export type CustomFieldsResult = {
  fields: EmployeeImportMappingField[]
}

function formatExternalMappingFieldType(fieldType: ExternalMappingFieldType): string {
  switch (fieldType) {
    case 'text':
      return t('data_integration.custom_field_mapping.form.field_type.text')
    case 'number':
      return t('data_integration.custom_field_mapping.form.field_type.number')
    case 'boolean':
      return t('data_integration.custom_field_mapping.form.field_type.boolean')
    case 'multi':
      return t('data_integration.custom_field_mapping.form.field_type.multi')
    case 'date':
      return t('data_integration.custom_field_mapping.form.field_type.date')
    case 'leaveType':
      return t('data_integration.custom_field_mapping.form.field_type.leave_type')
  }
}

function formatInternalMappingFieldType(
  fieldType: InternalMappingFieldType,
  allowedTypes: ExternalMappingFieldType[]
): string {
  switch (fieldType) {
    case 'Company National ID':
      return t('data_integration.custom_field_mapping.form.field.company_national_id')
    case 'Company Name':
      return t('data_integration.custom_field_mapping.form.field.company_name')
    case 'Company ID':
      return t('data_integration.custom_field_mapping.form.field.company_id')
    case 'Department ID Limit':
      return t('data_integration.custom_field_mapping.form.field.company_id_limit')
    case 'National ID':
      return t('data_integration.custom_field_mapping.form.field.national_id')
    case 'Employee Number':
      return t('data_integration.custom_field_mapping.form.field.employee_number')
    case 'Email':
      return t('data_integration.custom_field_mapping.form.field.email')
    case 'Address':
      return t('data_integration.custom_field_mapping.form.field.address')
    case 'City':
      return t('data_integration.custom_field_mapping.form.field.city')
    case 'Postal Code':
      return t('data_integration.custom_field_mapping.form.field.postal_code')
    case 'Bank Registration Number':
      return t('data_integration.custom_field_mapping.form.field.bank_registration_number')
    case 'Bank Account Number':
      return t('data_integration.custom_field_mapping.form.field.bank_account_number')
    case 'Start Date':
      return t('data_integration.custom_field_mapping.form.field.start_date')
    case 'Salary Fixed':
      if (allowedTypes.some((type) => type === 'multi')) {
        return t('data_integration.custom_field_mapping.form.field.salary_fixed.multi')
      }
      return t('data_integration.custom_field_mapping.form.field.salary_fixed')
    case 'Salary Hourly':
      if (allowedTypes.some((type) => type === 'multi')) {
        return t('data_integration.custom_field_mapping.form.field.salary_hourly.multi')
      }
      return t('data_integration.custom_field_mapping.form.field.salary_hourly')
    case 'Salary Rate':
      return t('data_integration.custom_field_mapping.form.field.salary_rate')
    case 'Benefit Free Phone':
      return t('data_integration.custom_field_mapping.form.field.benefit_free_phone')
    case 'Benefit Lunch':
      return t('data_integration.custom_field_mapping.form.field.benefit_lunch')
    case 'Benefit Health':
      return t('data_integration.custom_field_mapping.form.field.benefit_health')
    case 'Tax Card Primary':
      return t('data_integration.custom_field_mapping.form.field.tax_card_primary')
    case 'Tax Card Secondary':
      return t('data_integration.custom_field_mapping.form.field.tax_card_secondary')
    case 'Leave VacationAccrual':
      return t('data_integration.custom_field_mapping.form.field.leave_vacation_accrual')
    case 'Leave VacationFund':
      return t('data_integration.custom_field_mapping.form.field.leave_vacation_fund')
    case 'Leave Sick':
      return t('data_integration.custom_field_mapping.form.field.leave_sick')
    case 'Leave DayOff':
      return t('data_integration.custom_field_mapping.form.field.leave_day_off')
    case 'Leave PersonalDay':
      return t('data_integration.custom_field_mapping.form.field.leave_personal_day')
    case 'Leave ExtraDay':
      return t('data_integration.custom_field_mapping.form.field.leave_extra_day')
    case 'Leave Unpaid':
      return t('data_integration.custom_field_mapping.form.field.leave_unpaid')
  }
}

function formatFieldDescription(
  fieldType: InternalMappingFieldType,
  allowedTypes: ExternalMappingFieldType[],
  showValueSelector: boolean
): ReactNode {
  if (fieldType === 'Company Name') {
    return tx('data_integration.custom_field_mapping.form.field_description.company_name', {
      field: <strong>{t('data_integration.custom_field_mapping.form.field.company_name')}</strong>,
    })
  }
  if (fieldType === 'Company National ID') {
    return tx('data_integration.custom_field_mapping.form.field_description.company_national_id', {
      field: <strong>{t('data_integration.custom_field_mapping.form.field.company_national_id')}</strong>,
    })
  }
  if (fieldType === 'Company ID') {
    return tx('data_integration.custom_field_mapping.form.field_description.company_id', {
      field: <strong>{t('data_integration.custom_field_mapping.form.field.company_id')}</strong>,
    })
  }
  if (fieldType === 'Department ID Limit') {
    return tx('data_integration.custom_field_mapping.form.field_description.department_id_limit', {
      field: <strong>{t('data_integration.custom_field_mapping.form.field.department_id_limit')}</strong>,
    })
  }
  if (allowedTypes.some((type) => type === 'number')) {
    return tx('data_integration.custom_field_mapping.form.field_description.number', {
      field: <strong>{t('data_integration.custom_field_mapping.form.field_type.number')}</strong>,
    })
  }
  if (allowedTypes.some((type) => type === 'text') && showValueSelector) {
    return tx('data_integration.custom_field_mapping.form.field_description.text_selector', {
      field: <strong>{t('data_integration.custom_field_mapping.form.field_type.text_selector')}</strong>,
    })
  }
  if (allowedTypes.some((type) => type === 'boolean')) {
    return tx(
      showValueSelector
        ? 'data_integration.custom_field_mapping.form.field_description.boolean.selector'
        : 'data_integration.custom_field_mapping.form.field_description.boolean',
      {
        field: <strong>{t('data_integration.custom_field_mapping.form.field_type.boolean')}</strong>,
      }
    )
  }
  if (allowedTypes.some((type) => type === 'multi') && !allowedTypes.some((type) => type === 'boolean')) {
    return tx('data_integration.custom_field_mapping.form.field_description.multi', {
      field: <strong>{t('data_integration.custom_field_mapping.form.field_type.multi')}</strong>,
    })
  }
  if (allowedTypes.some((type) => type === 'date')) {
    return tx('data_integration.custom_field_mapping.form.field_description.date', {
      field: <strong>{t('data_integration.custom_field_mapping.form.field_type.date')}</strong>,
    })
  }
  if (allowedTypes.some((type) => type === 'text')) {
    return tx('data_integration.custom_field_mapping.form.field_description.text', {
      field: <strong>{t('data_integration.custom_field_mapping.form.field_type.text')}</strong>,
    })
  }
  if (allowedTypes.some((type) => type === 'leaveType')) {
    return tx('data_integration.custom_field_mapping.form.field_description.leave_type', {
      field: <strong>{t('data_integration.custom_field_mapping.form.field_type.leave_type')}</strong>,
    })
  }
  return null
}

function CustomFieldMappingForm(props: Props & FormComponentProps<Fields, CustomFieldsResult>): ReactElement | null {
  const { decorateField, getFieldValue } = props
  return (
    <div>
      <Row>
        <Col span={12}>
          <strong>{t('data_integration.custom_field_mapping.form.header.salary_field')}</strong>
        </Col>
        <Col span={12}>
          <strong>
            {t('data_integration.custom_field_mapping.form.header.external_field', { title: props.displayName })}
          </strong>
        </Col>
      </Row>
      {props.state.rules.map((rule) => {
        const fieldType = rule.field
        const externalField = props.state.externalFields.find(
          (field) => field.externalField === rule.externalFieldName ?? getFieldValue(`field-${fieldType}`)
        )
        const showFieldSelector = !rule.externalFieldName
        const showValueSelector = !!externalField && (rule.requireValue || externalField.type === 'multi')
        const hasValues = !!externalField?.values
        const error = props.getFieldError(`field-${fieldType}`)
        const fieldDisplayName = formatInternalMappingFieldType(fieldType, rule.allowedTypes)
        return (
          <Row key={fieldType}>
            <Col span={12}>
              {error ? (
                <span className="ant-form-error no-icon">{error}</span>
              ) : (
                <>
                  {fieldDisplayName}
                  {rule.required && (
                    <>
                      {' '}
                      <strong>{t('data_integration.custom_field_mapping.form.field_required')}</strong>
                    </>
                  )}
                </>
              )}
              <br />
              <small>{formatFieldDescription(rule.field, rule.allowedTypes, showValueSelector)}</small>
            </Col>
            {showFieldSelector && (
              <Col span={showValueSelector ? 6 : 12}>
                {decorateField(`field-${fieldType}`, {
                  skipLabel: true,
                  placeholder: fieldDisplayName,
                  validate: (val) => {
                    if (!rule.required) {
                      return null
                    }
                    if (!val) {
                      return t('data_integration.custom_field_mapping.form.selector.required', {
                        title: fieldDisplayName,
                      })
                    }
                    return null
                  },
                })(
                  <Select
                    dropdownMatchSelectWidth={false}
                    showSearch={true}
                    filterOption={(inputValue: string, option: ReactElement) => {
                      return option.props.title.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                    }}
                    style={showValueSelector ? { marginTop: '24px' } : {}}
                  >
                    <Select.Option
                      key={'-'}
                      value={''}
                      title={t('data_integration.custom_field_mapping.form.selector.none')}
                    >
                      <i>{t('data_integration.custom_field_mapping.form.selector.none')}</i>
                    </Select.Option>
                    {props.state.externalFields
                      .filter((field) => rule.allowedTypes.some((allowed) => allowed === field.type))
                      .map((externalField) => {
                        return (
                          <Select.Option
                            key={externalField.externalField}
                            value={externalField.externalField}
                            title={externalField.externalName}
                          >
                            {externalField.externalName} ({formatExternalMappingFieldType(externalField.type)})
                          </Select.Option>
                        )
                      })}
                  </Select>
                )}
              </Col>
            )}
            {showValueSelector && (
              <Col span={showFieldSelector ? 6 : 12}>
                {decorateField(`value-${fieldType}`, {
                  placeholder: t('data_integration.custom_field_mapping.form.value', {
                    title: externalField.externalName ?? fieldDisplayName,
                  }),
                  validate: (val) => {
                    if (!val) {
                      return t('data_integration.custom_field_mapping.form.value.required', {
                        title: externalField.externalName ?? fieldDisplayName,
                      })
                    }
                    return null
                  },
                })(
                  hasValues ? (
                    <Select dropdownMatchSelectWidth={false}>
                      {externalField.values!.map((value) => {
                        return (
                          <Select.Option key={value.value} value={value.value}>
                            {value.displayValue || value.value}
                          </Select.Option>
                        )
                      })}
                    </Select>
                  ) : (
                    <Input />
                  )
                )}
                {!hasValues && (
                  <small>
                    {t('data_integration.custom_field_mapping.form.value.note', { title: props.displayName })}
                  </small>
                )}
              </Col>
            )}
          </Row>
        )
      })}
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="large" type="secondary">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default withValidations<Props, Fields, CustomFieldsResult>({
  mapPropsToFields: (props) => {
    return props.state.mapping.reduce((list: Fields, field) => {
      list[`field-${field.field}`] = field.externalField
      if (field.externalValue) {
        list[`value-${field.field}`] = field.externalValue
      }
      return list
    }, {})
  },
  onSubmit: (values, props) => {
    return {
      fields: props.state.rules.reduce((fields: EmployeeImportMappingField[], rule) => {
        const fieldType = rule.field
        const externalField = values[`field-${fieldType}`]
        const externalValue = values[`value-${fieldType}`]
        if (externalField) {
          const field: EmployeeImportMappingField = {
            field: fieldType,
            externalField: externalField,
          }
          if (externalValue) {
            field.externalValue = externalValue
          }
          fields.push(field)
        }
        return fields
      }, []),
    }
  },
})(CustomFieldMappingForm)
