import React, { ReactElement, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import { harvest } from '../../api/harvest'
import {
  CompanyCreationFields,
  CompanyDKSpecific,
  PayRollRunApprovalRule,
  ProductionUnitCreationFields,
} from '../../model/company'
import { CompanyUserNotificationFields } from '../../model/companyUser'
import { BasicSalaryCycle } from '../../model/salaryCycle'
import { SignupCompanyRole } from '../../model/user'
import { AlertReducer } from '../../reducers/alerts'
import { CompanyReducer } from '../../reducers/companies'
import { UserReducer } from '../../reducers/user'
import CompanySize from '../../types/company-size'
import { regularComponentDidUpdate } from '../../utils/component-utils'
import { formatError } from '../../utils/error-utils'
import { trackGaEvent } from '../../utils/tracking-utils'
import { t, tx } from '../../utils/translation-utils'
import { getCityFromPostalCode } from '../../utils/validation-utils'
import Alert from '../elements/alert'
import Card from '../elements/card'
import Subtitle from '../elements/Subtitle'
import Alerts from '../widgets/Alerts'
import jsBrowserHistory from '../widgets/jsBrowserHistory'
import LoadingOverlay from '../widgets/LoadingOverlay'
import BaseDataForm, { AccountingSoftware, DataResult } from './BaseDataForm'
import CompanySearch, { SearchResult } from './CompanySearch'

import './CompaniesAdd.css'

type Props = {
  alerts: AlertReducer
  companies: CompanyReducer
  companyGroupID?: string
  user: UserReducer
  salaryCycles: BasicSalaryCycle[]

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  addCompany: (company: CompanyCreationFields) => void
  addCompanyToCompanyGroup: (companyGroupID: string, companyID: string) => void
  updateActiveCompany: (companyID: string) => void
}

type State = {
  nationalID?: string
  name?: string
  address?: string
  postalCode?: string
  city?: string
  rulePayRollRunApproval: PayRollRunApprovalRule
  dkSpecific: CompanyDKSpecific
  productionUnits: ProductionUnitCreationFields[]
} & CompanyUserNotificationFields

type Fields = {
  companySize?: string
  accountingSoftware?: AccountingSoftware
  companyRole?: SignupCompanyRole
  referredBy?: string
}

export default function CompaniesAdd(props: Props): ReactElement | null {
  const [state, setState] = useState<State>({
    notificationPayRollAutomaticallyStarted: true,
    notificationPayRollDone: true,
    notificationPayRollDeadlineUpcoming: true,
    notificationPayRollApprovalUpcoming: true,
    notificationSwipeAwaitingApproval: false,
    notificationAutomaticZeroTaxReportDone: false,
    notificationTransferInfo: true,
    notificationEmployeeUpdatedBanking: true,
    rulePayRollRunApproval: 'Manual',
    dkSpecific: {
      skatSize: CompanySize.SMALL,
      allowAutomaticZeroTaxReport: true,
    },
    productionUnits: [],
  })
  const [fields, setFields] = useState<Fields>({})
  const [error, setError] = useState<Error | null>(null)

  const { companies, companyGroupID, addCompanyToCompanyGroup, addAlert, updateActiveCompany, user } = props
  const previousCompanies = usePrevious(companies)

  useEffect(() => {
    // Check for save callback
    if (previousCompanies && previousCompanies.companies.size < companies.companies.size) {
      // Check for no error occurred
      if (!companies.error) {
        // Find newly created company
        const company = companies.companies.find((company) => company.nationalID === state.nationalID)
        if (company) {
          const id = company.id

          if (companyGroupID) {
            // add to company group
            addCompanyToCompanyGroup(companyGroupID, id)
          }

          // Save number of employees and accounting software (fire and forget)
          harvest(undefined, id, {
            SignupEmployeeCount: fields.companySize,
            SignupAccountingSystem: fields.accountingSoftware,
          })
          const canTrack =
            !user.noExternalTracking && !company.settingsEnabled.some((setting) => setting === 'NoExternalTracking')
          if (canTrack) {
            // Track company creation
            trackGaEvent('Company', 'create')
          }
          // Show info alert
          addAlert('success', t('companies_add.alert.success', { name: company.name }), { timeout: 5 })
          // Set active company
          updateActiveCompany(company.id)
        }
        harvest(user.id, undefined, {
          SignupCompanyRole: fields.companyRole,
          SignupReferredBy: fields.referredBy,
        })
        // Redirect to dashboard
        jsBrowserHistory.push('/')
      }
    }
  }, [
    previousCompanies,
    companies,
    companyGroupID,
    addAlert,
    updateActiveCompany,
    fields,
    state,
    addCompanyToCompanyGroup,
    user,
  ])

  useEffect(() => {
    regularComponentDidUpdate(companies.error, error, setError)
  }, [companies, error])

  const _updateCompany = (res: SearchResult) => {
    setState((prev) => ({
      ...prev,
      ...{
        nationalID: res.nationalID,
        name: res.name,
        address: res.address,
        postalCode: res.postalCode,
        city: getCityFromPostalCode(res.postalCode) || res.city,
        productionUnits: res.productionUnits,
      },
    }))
  }

  const _handleSubmit = (values: DataResult) => {
    const stateValues: Omit<DataResult, 'companySize' | 'accountingSoftware' | 'companyRole' | 'referredBy'> = {
      name: values.name,
      address: values.address,
      postalCode: values.postalCode,
      city: values.city,
      dkSpecific: values.dkSpecific,
    }
    const fieldValues: Fields = {
      companySize: values.companySize,
      accountingSoftware: values.accountingSoftware,
      companyRole: values.companyRole,
      referredBy: values.referredBy,
    }
    setState((prev) => ({ ...prev, ...stateValues }))
    setFields((prev) => ({ ...prev, ...fieldValues }))
    const combined = { ...state, ...stateValues }
    const company = {
      nationalID: combined.nationalID || '',
      name: combined.name,
      address: combined.address,
      postalCode: combined.postalCode,
      city: combined.city,
      notificationPayRollAutomaticallyStarted: combined.notificationPayRollAutomaticallyStarted,
      notificationPayRollDone: combined.notificationPayRollDone,
      notificationPayRollDeadlineUpcoming: combined.notificationPayRollDeadlineUpcoming,
      notificationPayRollApprovalUpcoming: combined.notificationPayRollApprovalUpcoming,
      notificationSwipeAwaitingApproval: combined.notificationSwipeAwaitingApproval,
      notificationAutomaticZeroTaxReportDone: combined.notificationAutomaticZeroTaxReportDone,
      notificationTransferInfo: combined.notificationTransferInfo,
      notificationEmployeeUpdatedBanking: combined.notificationEmployeeUpdatedBanking,
      rulePayRollRunApproval: combined.rulePayRollRunApproval,
      dkSpecific: combined.dkSpecific,
      productionUnits: combined.productionUnits,
    }
    props.addCompany(company)
  }

  return (
    <div className="companies-add">
      <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />
      {props.user.user?.userCompanies === 0 && props.user.user.userEmployees > 0 && (
        <Alert
          type="warning"
          style={{ fontSize: '16pt', lineHeight: '18pt' }}
          message={
            <>
              {tx('companies_add.employee_user_notice.line_1', {
                link: (
                  <a href="https://employee.salary.dk" target="_blank" rel="noopener noreferrer">
                    {t('companies_add.employee_user_notice.line_1.link')}
                  </a>
                ),
              })}
              <br />
              {t('companies_add.employee_user_notice.line_2')}
            </>
          }
          showIcon
        />
      )}
      <Card>
        <Subtitle>{t('companies_add.edit.title')}</Subtitle>

        <CompanySearch onSelect={_updateCompany} />

        {error && <Alert message={formatError(error)} type="error" showIcon />}
        {state.nationalID && (
          <BaseDataForm
            companies={props.companies.companies}
            salaryCycles={props.salaryCycles}
            name={state.name}
            address={state.address}
            postalCode={state.postalCode}
            city={state.city}
            companySize={fields.companySize}
            dkSpecific={state.dkSpecific}
            onSubmit={_handleSubmit}
          />
        )}
        {props.companies.saving && <LoadingOverlay />}
      </Card>
    </div>
  )
}
