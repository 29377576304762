import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../../actions/alerts'
import { TimeRegistrationBulk } from '../../../api/time-registrations'
import paths from '../../../constants/paths'
import Company from '../../../model/company'
import Employee from '../../../model/employee'
import LeaveType from '../../../model/leaveType'
import TimeRegistration, { TimeRegistrationClass, TimeRegistrationMutableFields } from '../../../model/timeRegistration'
import { DateFormat } from '../../../model/types'
import { LeaveBalanceReducer } from '../../../reducers/leaveBalances'
import { TimeRegistrationReducer } from '../../../reducers/timeRegistrations'
import { VacationCalendarReducer } from '../../../reducers/vacationCalendars'
import { formatAPIDate } from '../../../utils/date-utils'
import { formatLeaveTypeName } from '../../../utils/format-utils'
import { formatDisplayNumber } from '../../../utils/number-utils'
import { t } from '../../../utils/translation-utils'
import Modal from '../../antd/modal'
import Button from '../../elements/button'
import Card from '../../elements/card'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Icon from '../../elements/Icon'
import Title from '../../elements/Title'
import TitleMenu from '../../elements/TitleMenu'
import LoadingOverlay from '../../widgets/LoadingOverlay'
import NoContractCard from '../NoContractCard'
import NoEmploymentCard from '../NoEmploymentCard'
import LeaveEdit from './LeaveEdit'
import LeaveTabCalendar from './LeaveTabCalendar'
import LeaveTabTable from './LeaveTabTable'

type Props = {
  company: Company
  employee: Employee
  timeRegistrations: TimeRegistrationReducer
  leaveBalances: LeaveBalanceReducer
  leaveTypes: List<LeaveType>
  vacationCalendars: VacationCalendarReducer
  canEditObjects: boolean
  canApproveObjects: boolean

  addAlert: addAlertSignature
  createTimeRegistration: (registration: TimeRegistrationMutableFields) => void
  createTimeRegistrationBulk: (bulk: TimeRegistrationBulk) => void
  updateTimeRegistration: (registration: TimeRegistrationMutableFields) => void
  approveTimeRegistrations: (ids: string[]) => void
  unapproveTimeRegistrations: (ids: string[]) => void
  deleteTimeRegistration: (id: string) => void
  deleteTimeRegistrationBulk: (
    companyID: string | undefined,
    employeeID: string | undefined,
    type: TimeRegistrationClass
  ) => void
  getLeaveBalances: (companyID?: string, employeeID?: string) => void
  getVacationCalendarYear: (companyID: string, year: number) => void
}

export default function LeaveTab(props: Props): ReactElement | null {
  const [calendarMode, setCalendarMode] = useState(false)
  const [approving, setApproving] = useState<string[]>([])
  const [deleting, setDeleting] = useState<string[]>([])
  const [modalKey, setModalKey] = useState(1)
  const [editing, setEditing] = useState<string | boolean>(false)
  const [newDate, setNewDate] = useState<DateFormat | null>(null)

  const { employee, leaveBalances, getLeaveBalances } = props
  useEffect(() => {
    const employeeID = employee.id
    if (leaveBalances.employeeID !== employeeID || (!leaveBalances.loading && !leaveBalances.loaded)) {
      getLeaveBalances(undefined, employeeID)
    }
  })

  const setEditVisibility = (id: string | boolean, date?: Date) => {
    setModalKey((prev) => prev + 1)
    setEditing(id)
    setNewDate(date ? formatAPIDate(date) : null)
  }

  const { timeRegistrations } = props
  const previousTimeRegistrations = usePrevious(timeRegistrations)

  useEffect(() => {
    if (previousTimeRegistrations && previousTimeRegistrations.saving && !timeRegistrations.saving) {
      if (!timeRegistrations.error) {
        setEditVisibility(false)
      }
    }
  })

  const getLeaveTypeById = (id: string) => {
    return props.leaveTypes.find((type) => type.id === id)
  }

  const regFilter = (reg: TimeRegistration) => reg.class === 'Leave' && reg.employeeID === props.employee.id

  const approve = (timeRegistrationID: string) => {
    setApproving((prev) => [...prev, timeRegistrationID])
    props.approveTimeRegistrations([timeRegistrationID])
  }
  const unapprove = (timeRegistrationID: string) => {
    setApproving((prev) => prev.filter((id) => id !== timeRegistrationID))
    props.unapproveTimeRegistrations([timeRegistrationID])
  }
  const approveAll = (e: React.MouseEvent) => {
    e.preventDefault()
    if (window.confirm(t('common.are_you_sure'))) {
      const registrations = props.timeRegistrations.timeRegistrations
        .filter((reg) => regFilter(reg) && !reg.approved)
        .toArray()
      setApproving(registrations.map((reg) => reg.id))
      props.approveTimeRegistrations(registrations.map((reg) => reg.id))
    }
  }
  const remove = (timeRegistrationID: string) => {
    if (window.confirm(t('common.are_you_sure'))) {
      setDeleting((prev) => [...prev, timeRegistrationID])
      props.deleteTimeRegistration(timeRegistrationID)
    }
  }

  const hasUnapprovedTimeRegistrations = () => {
    return props.timeRegistrations.timeRegistrations.some((reg) => regFilter(reg) && !reg.approved)
  }

  const bulkDelete = () => {
    if (window.confirm(t('leave.tab.confirm.bulk_delete'))) {
      props.deleteTimeRegistrationBulk(undefined, props.employee.id, 'Leave')
    }
  }

  if (!props.leaveBalances.loaded) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay />
      </div>
    )
  }

  if (!props.employee.activeEmployment) {
    return <NoEmploymentCard />
  }
  if (
    !props.employee.activeContract ||
    props.employee.activeEmployment.id !== props.employee.activeContract.employmentID
  ) {
    return <NoContractCard employee={props.employee} />
  }

  const remunerationType = props.employee.activeContract && props.employee.activeContract.remunerationType
  const vacationLimit = (props.employee.vacationExcessLimit ?? props.company.vacationExcessLimit) || 0

  return (
    <Card className="employees-single-form">
      <TitleMenu>
        {props.timeRegistrations.timeRegistrations.some((reg) => regFilter(reg) && reg.immutable) &&
          props.canEditObjects && (
            <Button onClick={() => bulkDelete()} type="danger" style={{ marginRight: '10px' }}>
              <Icon type="cross" color="red" />
              {t('leave.tab.header.bulk_delete')}
            </Button>
          )}
        {props.canEditObjects && (
          <Button onClick={() => setEditVisibility(true)}>
            <Icon type="stopwatch" color="grey" />
            {t('leave.tab.header.add_new')}
          </Button>
        )}

        {hasUnapprovedTimeRegistrations() && props.canApproveObjects && (
          <Button onClick={approveAll} style={{ marginLeft: 20 }}>
            {t('leave.tab.header.approve_all')}
          </Button>
        )}
      </TitleMenu>
      <Title>
        {t('leave.tab.header.title')}
        <Link to={'/' + paths.LEAVE_REGISTRATION + '/calendar'} className={'subtitle-link'}>
          {t('leave.tab.header.link_to_calendar')}
        </Link>
      </Title>
      <Row>
        {props.leaveBalances.leaveBalances
          .filter(
            (leaveBalance) =>
              leaveBalance.employeeID === props.employee.id &&
              getLeaveTypeById(leaveBalance.leaveTypeID)?.unit === 'Days'
          )
          .map((leaveBalance) => {
            const leaveType = getLeaveTypeById(leaveBalance.leaveTypeID)!
            const used = formatDisplayNumber(leaveBalance.used + leaveBalance.registered)
            const hasFuture = leaveBalance.registeredFuture > 0
            const future = formatDisplayNumber(leaveBalance.registeredFuture)
            const left = formatDisplayNumber(leaveBalance.left)
            let borrowedNumber = 0
            const showBorrowed =
              leaveType.name === 'DenmarkVacationPaid' ||
              leaveType.name === 'DenmarkVacationPaidAdditional' ||
              leaveType.name === 'DenmarkVacationNoPay' ||
              leaveType.name === 'DenmarkVacationAccrual' ||
              leaveType.name === 'DenmarkVacationFund' ||
              leaveType.name === 'DenmarkVacationFundWithPension'

            if (showBorrowed && leaveBalance.left < 0 && vacationLimit > 0) {
              borrowedNumber = Math.abs(leaveBalance.left)
              if (borrowedNumber > vacationLimit) {
                borrowedNumber = vacationLimit
              }
            }
            const hasBorrowed = showBorrowed && borrowedNumber !== 0
            const borrowed = formatDisplayNumber(borrowedNumber)
            return (
              <Col key={leaveBalance.leaveTypeID} span={8}>
                {leaveType
                  ? formatLeaveTypeName(leaveType.name, true, remunerationType)
                  : t('leave.tab.balances.unknown')}
                :
                {leaveType && (
                  <>
                    <br />
                    <strong>
                      {leaveType && !leaveType.assignable
                        ? t(
                            hasFuture
                              ? 'leave.tab.balances.not_assignable.with_future'
                              : 'leave.tab.balances.not_assignable.standard',
                            { used, future }
                          )
                        : t(
                            hasFuture
                              ? hasBorrowed
                                ? 'leave.tab.balances.assignable.with_future_borrowed'
                                : 'leave.tab.balances.assignable.with_future'
                              : hasBorrowed
                              ? 'leave.tab.balances.assignable.with_borrowed'
                              : 'leave.tab.balances.assignable.standard',
                            { used, future, left, borrowed }
                          )}
                    </strong>
                  </>
                )}
              </Col>
            )
          })}
      </Row>
      <p>&nbsp;</p>
      <div style={{ textAlign: 'right' }}>
        <Button type="primary" onClick={() => setCalendarMode((prev) => !prev)} style={{ marginBottom: '15px' }}>
          {calendarMode ? t('leave.tab.switch_to_table') : t('leave.tab.switch_to_calendar')}
        </Button>
      </div>

      {!calendarMode && (
        <LeaveTabTable
          employee={props.employee}
          timeRegistrations={props.timeRegistrations}
          leaveTypes={props.leaveTypes}
          canEditObjects={props.canEditObjects}
          canApproveObjects={props.canApproveObjects}
          approving={approving}
          unapprove={unapprove}
          approve={approve}
          remove={remove}
          deleting={deleting}
          timeRegistrationFilter={regFilter}
          setEditVisibility={setEditVisibility}
        />
      )}
      {calendarMode && (
        <LeaveTabCalendar
          company={props.company}
          timeRegistrations={props.timeRegistrations}
          timeRegistrationFilter={regFilter}
          vacationCalendars={props.vacationCalendars}
          leaveTypes={props.leaveTypes}
          setEditVisibility={setEditVisibility}
          getVacationCalendarYear={props.getVacationCalendarYear}
        />
      )}

      <Modal
        key={modalKey}
        visible={editing !== false}
        onOk={() => setEditVisibility(false)}
        onCancel={() => setEditVisibility(false)}
        width={582}
        footer={null}
      >
        <LeaveEdit
          visible={editing !== false}
          period={editing === true && !!newDate ? [newDate, newDate] : undefined}
          company={props.company}
          employee={props.employee}
          canApproveObjects={props.canApproveObjects}
          timeRegistrationID={typeof editing === 'string' ? editing : undefined}
          timeRegistrations={props.timeRegistrations}
          leaveTypes={props.leaveTypes}
          remunerationType={remunerationType}
          createTimeRegistration={props.createTimeRegistration}
          createTimeRegistrationBulk={props.createTimeRegistrationBulk}
          updateTimeRegistration={props.updateTimeRegistration}
          deleteTimeRegistration={calendarMode ? props.deleteTimeRegistration : undefined}
        />
      </Modal>
    </Card>
  )
}
