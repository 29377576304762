import React, { ReactElement, useEffect, useState } from 'react'

import { removeAlertSignature } from '../../actions/alerts'
import logo from '../../images/logo-white-165x49.png'
import { AccessToken } from '../../model/user'
import { AlertReducer } from '../../reducers/alerts'
import { UserReducer } from '../../reducers/user'
import MfaChannel from '../../types/mfa-channel'
import { getEmail, setEmail } from '../../utils/cookie-utils'
import { formatError, isMFAChannelError } from '../../utils/error-utils'
import { ChannelMFAError, RequestError } from '../../utils/request-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Card from '../elements/card'
import Headline from '../elements/Headline'
import Title from '../elements/Title'
import Alerts from '../widgets/Alerts'
import LoadingOverlay from '../widgets/LoadingOverlay'
import LoginForm, { Fields } from './LoginForm'

import './Login.css'

type Props = {
  user: UserReducer
  alerts: AlertReducer

  removeAlert: removeAlertSignature
  login: (
    email: string,
    password: string,
    challengeID?: string,
    response?: string,
    recoveryCode?: string,
    trustDevice?: boolean
  ) => Promise<AccessToken | ChannelMFAError | void>
}

type Elements = {
  email?: string
  password?: string
  channel?: MfaChannel
  challengeID?: string
  remember: boolean
}

export default function Login(props: Props): ReactElement | null {
  const [elements, setElements] = useState<Elements>({
    email: undefined,
    password: undefined,
    channel: undefined,
    challengeID: undefined,
    remember: false,
  })
  const [error, setError] = useState<Error | null>(null)

  const { user } = props

  useEffect(() => {
    let newError = user.error
    if (newError instanceof RequestError && newError.type === 'Authorization') {
      newError = null
    }
    if (error !== newError) {
      setError(newError)
    }
  }, [user, error])

  const _handleSubmit = (values: Fields) => {
    const email = values.email || elements.email
    if (!email) {
      return
    }
    const password = values.password || elements.password
    if (!password) {
      return
    }
    props
      .login(email, password, elements.challengeID, values.response, values.recoveryCode, values.trustDevice)
      .then((res) => {
        if (res && isMFAChannelError(res)) {
          const mfa = res as ChannelMFAError
          setElements({
            email: values.email,
            password: values.password,
            channel: mfa.channel,
            challengeID: mfa.mfaChallengeID,
            remember: values.remember,
          })
        } else {
          if (values.remember || elements.remember) {
            setEmail(values.email || null, 14 * 24 * 60 * 60)
          }
        }
      })
      .catch((e) => {
        if (isMFAChannelError(e)) {
          setElements({
            email: values.email,
            password: values.password,
            channel: e.channel,
            challengeID: e.mfaChallengeID,
            remember: values.remember,
          })
        }
      })
  }

  return (
    <Card className="login">
      <div className="login-image">
        <img src={logo} alt="Salary" />

        <div className="login-title">
          <Title>{t('login.splash.title')}</Title>
          <Headline>{t('login.splash.subtitle')}</Headline>
        </div>
      </div>
      <div className="login-form">
        <Title>{t('login.title')}</Title>
        <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />
        {error && <Alert message={formatError(error)} type="error" showIcon />}
        <LoginForm email={getEmail()} channel={elements.channel} remember={!!getEmail()} onSubmit={_handleSubmit} />
        {user.loggingIn && <LoadingOverlay />}
      </div>
    </Card>
  )
}
