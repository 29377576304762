declare global {
  interface Window {
    dataLayer: any
  }
}

export function trackGaEvent(category: string, action: string) {
  if (!process.env.REACT_APP_ENABLE_TRACKING) {
    return
  }
  if (window.dataLayer) {
    // https://developers.google.com/tag-manager/devguide#events
    window.dataLayer.push({ event: action, event_category: category })
  }
}
