import Fuse from 'fuse.js'
import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react'

import { fetchIntercomArticles } from '../../api/intercom'
import paths from '../../constants/paths'
import { EmployeeReducer } from '../../reducers/employees'
import { formatError, isRequestError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import { Row } from '../elements/grid'
import Input from '../elements/input/Input'
import jsBrowserHistory from '../widgets/jsBrowserHistory'
import GlobalSearchCard from './GlobalSearchCard'

import './GlobalSearch.css'

type Props = {
  id: number
  employees: EmployeeReducer
  employeesLimit: number
  articlesLimit: number

  removeModal: (id: number) => void
}

export type GlobalSearchRow = {
  id: string
  title: string
  description?: string
  url: string
  target: string
}

export default function GlobalSearch(props: Props): ReactElement | null {
  const [inputSearchTerm, setInputSearchTerm] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const [employeeResults, setEmployeeResults] = useState<GlobalSearchRow[]>()
  const [articleResults, setArticleResults] = useState<GlobalSearchRow[]>()
  const [error, setError] = useState<Error | null>(null)
  // Determines what searched element is selected.
  // When set to -1 there has been no arrow pressed yet.
  const [selectedPosition, setSelectedPosition] = useState(-1)
  const [fuseEmployees] = useState(() => {
    // Employees fuse options
    const fuseEmployeeOptions = {
      keys: ['name', 'address', 'email', 'nationalID', 'activeEmployment.employeeNumber'],
      threshold: 0.2,
    }
    return new Fuse(props.employees.employees.toArray(), fuseEmployeeOptions)
  })
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const searchTimeout = setTimeout(() => {
      setSearchTerm(inputSearchTerm)
    }, 250)
    return () => clearTimeout(searchTimeout)
  }, [inputSearchTerm])

  useEffect(() => {
    if (!inputRef.current) {
      return
    }
    inputRef.current.focus()
  }, [])

  const eventHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const employeeNumber = employeeResults?.length ?? 0
    const articleNumber = articleResults?.length ?? 0
    switch (e.key) {
      case 'ArrowDown':
        setSelectedPosition((prev) => {
          if (prev + 1 === employeeNumber + articleNumber) {
            return prev
          }
          return prev + 1
        })
        return

      case 'ArrowUp':
        setSelectedPosition((prev) => {
          if (prev === 0) {
            return prev
          }
          return prev - 1
        })
        return

      case 'Enter':
        if (employeeNumber > 0 && selectedPosition < employeeNumber && employeeResults) {
          const employee = employeeResults[selectedPosition]
          jsBrowserHistory.push('/' + paths.EMPLOYEES + '/' + employee.id)
          props.removeModal(props.id)
        } else if (articleNumber > 0 && selectedPosition >= employeeNumber && articleResults) {
          const article = articleResults[selectedPosition - employeeNumber]
          window.open(article.url, '_blank', 'noopener,noreferrer')
        }
    }
  }

  const { employeesLimit } = props

  const loadEmployees = useCallback(
    (term: string) => {
      const matches = fuseEmployees.search(term, { limit: employeesLimit })
      const employeeRows: GlobalSearchRow[] = []
      matches.map((e) => {
        let description = '-'
        if (e.item.address && e.item.postalCode && e.item.city) {
          description = `${e.item.address}, ${e.item.postalCode} ${e.item.city}`
        }
        employeeRows.push({
          title: e.item.name || e.item.email || '-',
          id: e.item.id,
          url: paths.EMPLOYEES + '/' + e.item.id,
          description,
          target: '_self',
        })
      })
      setEmployeeResults(employeeRows)
    },
    [employeesLimit, fuseEmployees]
  )

  const { articlesLimit } = props

  const loadArticles = useCallback(
    (term: string) => {
      fetchIntercomArticles(term, articlesLimit)
        .then((results) => {
          if (results === undefined) {
            setArticleResults([])
            return
          }
          const articleRows: GlobalSearchRow[] = []
          results.data.map((e) => {
            articleRows.push({
              id: e.externalLink,
              title: e.title,
              description: e.description,
              url: e.externalLink,
              target: '_blank',
            })
          })
          setArticleResults(articleRows)
        })
        .catch((e) => {
          if (isRequestError(e)) {
            setError(e)
          }
        })
    },
    [articlesLimit]
  )

  useEffect(() => {
    setSelectedPosition(-1)
    setArticleResults([])
    setEmployeeResults([])
    if (searchTerm === '') {
      return
    }
    loadEmployees(searchTerm)
    loadArticles(searchTerm)
  }, [searchTerm, loadArticles, loadEmployees])

  return (
    <div className="global-search">
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <Row>
        <Input
          ref={inputRef}
          autoFocus={true}
          onChange={(e) => {
            setInputSearchTerm(e.currentTarget.value.trim())
          }}
          onKeyUp={eventHandler}
          placeholder={t('header.global_search')}
        />
      </Row>
      <Row>
        <GlobalSearchCard
          title={t('global_search.employees')}
          rows={employeeResults}
          offset={0}
          position={selectedPosition}
        />
        <GlobalSearchCard
          title={t('global_search.articles')}
          rows={articleResults}
          offset={employeeResults?.length ?? 0}
          position={selectedPosition}
        />
      </Row>
    </div>
  )
}
