import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { TimeRegistrationBulk } from '../../api/time-registrations'
import paths from '../../constants/paths'
import Company from '../../model/company'
import CompanyUser from '../../model/companyUser'
import Department from '../../model/department'
import Employee from '../../model/employee'
import LeaveType, { LeaveTypeName } from '../../model/leaveType'
import { TimeRegistrationMutableFields } from '../../model/timeRegistration'
import { DateFormat } from '../../model/types'
import { EmployeeReducer } from '../../reducers/employees'
import { TimeRegistrationReducer } from '../../reducers/timeRegistrations'
import { VacationCalendarReducer } from '../../reducers/vacationCalendars'
import { buildDate, formatDate } from '../../utils/date-utils'
import { formatLeaveTypeName } from '../../utils/format-utils'
import { hasDepartmentPermission } from '../../utils/permissions-utils'
import { t } from '../../utils/translation-utils'
import Modal from '../antd/modal'
import Calendar from '../elements/calendar'
import LeaveEdit from '../employees-single/leave/LeaveEdit'

type Props = {
  company: Company
  companyUser?: CompanyUser
  employees: EmployeeReducer
  departments: List<Department>
  timeRegistrations: TimeRegistrationReducer
  vacationCalendars: VacationCalendarReducer
  leaveTypes: List<LeaveType>

  getVacationCalendarYear: (companyID: string, year: number) => void
  getRemuneration: (id: string) => void
  createTimeRegistration: (reg: TimeRegistrationMutableFields) => void
  createTimeRegistrationBulk: (bulk: TimeRegistrationBulk) => void
  updateTimeRegistration: (reg: TimeRegistrationMutableFields) => void
  approveTimeRegistrations: (ids: string[]) => void
  deleteTimeRegistration: (id: string) => void
}

export default function LeaveRegistrationCalendar(props: Props): ReactElement | null {
  const [modalKey, setModalKey] = useState(1)
  const [leaveEditEmployeeID, setLeaveEditEmployeeID] = useState<string | boolean>(false)
  const [leaveEditTimeRegID, setLeaveEditTimeRegID] = useState<string | undefined>(undefined)
  const [leavePeriod, setLeavePeriod] = useState<DateFormat[]>([])

  const getLeaveType = (id: string | undefined): LeaveType | undefined => {
    if (!id) {
      return undefined
    }
    return props.leaveTypes.find((leaveType) => leaveType.id === id)
  }

  const formatTooltip = (employee: Employee, date: Date, leaveType?: LeaveType): string => {
    let name = t('leave.type.denmark_vacation', { count: 1 })
    if (leaveType) {
      name = formatLeaveTypeName(leaveType.name)
    }
    return t('leave_registrations.calendar.tooltip_format', { type: name, name: employee.name, date: formatDate(date) })
  }

  const hasLeaveTypeName = (names: LeaveTypeName[]): boolean => {
    const leaveTypeIDs: string[] = []
    names.forEach((name) => {
      const leaveType = props.leaveTypes.find((leaveType) => leaveType.name === name)
      if (leaveType) {
        leaveTypeIDs.push(leaveType.id)
      }
    })
    if (leaveTypeIDs.length === 0) {
      return false
    }
    return props.timeRegistrations.timeRegistrations.some(
      (reg) => !!reg.leaveTypeID && leaveTypeIDs.some((id) => id === reg.leaveTypeID)
    )
  }

  const newLeavePeriod = (employeeID: string, fromDate: DateFormat, toDate: DateFormat) => {
    setModalKey((prev) => prev + 1)
    setLeavePeriod([fromDate, toDate])
    setLeaveEditEmployeeID(employeeID)
  }
  const setLeaveEditVisibility = (employeeID: string, timeRegID: string) => {
    setModalKey((prev) => prev + 1)
    setLeaveEditTimeRegID(timeRegID)
    setLeaveEditEmployeeID(employeeID)
  }
  const closeLeaveEdit = () => {
    setLeaveEditEmployeeID(false)
    setLeavePeriod([])
    setLeaveEditTimeRegID(undefined)
  }

  const { timeRegistrations } = props
  const previousTimeRegistrations = usePrevious(timeRegistrations)

  useEffect(() => {
    if (previousTimeRegistrations && previousTimeRegistrations.saving && !timeRegistrations.saving) {
      if (!timeRegistrations.error) {
        closeLeaveEdit()
      }
    }
  }, [previousTimeRegistrations, timeRegistrations])

  const employee = props.employees.employees.find((employee) => employee.id === leaveEditEmployeeID)

  const getLegends = () => {
    const legends = [{ className: 'employee-leave', title: t('leave.type.denmark_vacation', { count: 1 }) }]
    if (hasLeaveTypeName(['DenmarkPersonalDay', 'DenmarkPersonalTimeAccrual'])) {
      legends.push({ className: 'employee-leave personal', title: t('leave.type.denmark_personal', { count: 1 }) })
    }
    if (hasLeaveTypeName(['DenmarkOptionalVacation', 'DenmarkExtraVacationAccrual'])) {
      legends.push({
        className: 'employee-leave optional',
        title: t('leave.type.denmark_extra_vacation', { count: 1 }),
      })
    }
    legends.push({
      className: 'employee-leave unpaid-day-off',
      title: t('leave.type.denmark_unpaid_day_off', { count: 1 }),
    })
    legends.push({
      className: 'employee-leave leave-day',
      title: formatLeaveTypeName('DenmarkLeaveDay'),
    })
    legends.push({ className: 'employee-leave day-off', title: t('leave.type.denmark_day_off', { count: 1 }) })
    legends.push({ className: 'employee-leave sick', title: t('leave.type.denmark_sick_day_paid', { count: 1 }) })
    legends.push({ className: 'employee-leave remote', title: t('leave.type.denmark_remote_work', { count: 1 }) })
    return legends
  }

  return (
    <>
      <Calendar
        timeRegistrationFilter={(reg) => reg.class === 'Leave'}
        baseCellClassName="employee-leave"
        formatRegistrationCell={(timeRegs, employee, date) => {
          const timeReg = timeRegs[0] // just do one for now
          const leaveType = getLeaveType(timeReg.leaveTypeID)
          let additionalClass = ''
          let allowGradient = false
          if (leaveType) {
            switch (leaveType.name) {
              case 'DenmarkRemoteWorkDay':
                additionalClass = 'remote'
                break
              case 'DenmarkPersonalDay':
              case 'DenmarkPersonalTimeAccrual':
                additionalClass = 'personal'
                break
              case 'DenmarkOptionalVacation':
              case 'DenmarkExtraVacationAccrual':
                additionalClass = 'optional'
                break
              case 'DenmarkSickDayPaid':
                additionalClass = 'sick'
                break
              case 'DenmarkDayOff':
                additionalClass = 'day-off'
                break
              case 'DenmarkUnpaidDayOff':
                additionalClass = 'unpaid-day-off'
                break
              case 'DenmarkLeaveDay':
                additionalClass = 'leave-day'
                break
              default:
                allowGradient = true
                break
            }
          }
          const title = formatTooltip(employee, date, leaveType)
          const percentage = ((timeReg.days || 0) / 1) * 100
          return {
            title,
            additionalClass,
            allowGradient,
            percentage,
            amount: timeReg.days || 0,
          }
        }}
        formatRegistrationContextMenuItem={(timeReg) => {
          return t(
            timeReg.note
              ? 'leave_registrations.calendar.context_menu.format.with_note'
              : 'leave_registrations.calendar.context_menu.format.no_note',
            {
              days: t('unit.day_count', { count: timeReg.days }),
              note: timeReg.note,
            }
          )
        }}
        employeeLink={(employeeID) => '/' + paths.EMPLOYEES + '/' + employeeID + '/leave'}
        instructionText={<p>{t('leave_registrations.calendar.instruction_text')}</p>}
        company={props.company}
        employees={props.employees.employees}
        departments={props.departments}
        timeRegistrations={props.timeRegistrations.timeRegistrations}
        vacationCalendars={props.vacationCalendars}
        getVacationCalendarYear={props.getVacationCalendarYear}
        periodDragMode
        approveAllConfirmText={(needApproval, currentYear, currentMonth) =>
          t('leave_registrations.calendar.approve_all_text', {
            count: needApproval,
            month: formatDate(buildDate(currentYear, currentMonth), t('date.month_of_year')),
          })
        }
        legends={getLegends()}
        newTypeText={t('leave_registrations.calendar.new_type')}
        onNewPeriod={newLeavePeriod}
        onEditRegistration={setLeaveEditVisibility}
        onApproveTimeRegistrations={props.approveTimeRegistrations}
        allowMoreRegistrationsPerDay={false}
      />

      <Modal
        key={modalKey}
        visible={!!leaveEditEmployeeID}
        onOk={() => closeLeaveEdit()}
        onCancel={() => closeLeaveEdit()}
        width={582}
        footer={null}
      >
        <LeaveEdit
          visible={!!leaveEditEmployeeID}
          company={props.company}
          employee={employee}
          employees={props.employees}
          canApproveObjects={hasDepartmentPermission(
            props.companyUser,
            employee && employee.departmentID,
            'ApproveObjects'
          )}
          timeRegistrations={props.timeRegistrations}
          timeRegistrationID={leaveEditTimeRegID}
          leaveTypes={props.leaveTypes}
          remunerationType={employee && employee.activeContract && employee.activeContract.remunerationType}
          period={leavePeriod}
          getRemuneration={props.getRemuneration}
          createTimeRegistration={props.createTimeRegistration}
          createTimeRegistrationBulk={props.createTimeRegistrationBulk}
          updateTimeRegistration={props.updateTimeRegistration}
          deleteTimeRegistration={props.deleteTimeRegistration}
          approveTimeRegistrations={props.approveTimeRegistrations}
        />
      </Modal>
    </>
  )
}
