import React, { ReactElement } from 'react'
import { Link } from 'react-router'

import CustomTable from '../elements/custom-table'
import Subtitle from '../elements/Subtitle'
import { GlobalSearchRow } from './GlobalSearch'

type Props = {
  title: string
  rows?: GlobalSearchRow[]
  offset: number
  position: number
}

export default function GlobalSearchCard(props: Props): ReactElement | null {
  if (!props.rows || !props.rows.length) {
    return null
  }
  return (
    <div>
      {props.rows.length > 0 && (
        <CustomTable columns={1}>
          <CustomTable.Header>
            <CustomTable.TH>{props.title}</CustomTable.TH>
          </CustomTable.Header>
          <CustomTable.Body>
            {props.rows.map((row, i) => {
              return (
                <CustomTable.Row key={`${row.id}`}>
                  <CustomTable.TD
                    className={
                      'global-search-item' + (i + props.offset === props.position ? ' global-search-selected' : '')
                    }
                  >
                    <Link to={row.url}>
                      <Subtitle>{row.title}</Subtitle>
                      <p>{row.description}</p>
                    </Link>
                  </CustomTable.TD>
                </CustomTable.Row>
              )
            })}
          </CustomTable.Body>
        </CustomTable>
      )}
    </div>
  )
}
