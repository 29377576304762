import AsynchronousTask from '../model/asynchronousTask'
import DataIntegration from '../model/dataIntegration'
import PreferredTaxCardType from '../types/preferred-tax-card-type'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchCompanyDataIntegration(companyID: string): Promise<RequestResponse<DataIntegration>> {
  return secureRequest('GET', url('/v2/dataIntegrations', { companyID }))
}

export function putCompanyDataIntegration(state: string): Promise<RequestResponse<DataIntegration>> {
  return secureRequest('PUT', url('/v2/dataIntegrations', { state }))
}

export function delCompanyDataIntegration(companyID: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/dataIntegrations', { companyID }))
}

export function postDataIntegrationsEmployees(
  companyID: string,
  preferredTaxCard: PreferredTaxCardType
): Promise<RequestResponse<AsynchronousTask>> {
  return secureRequest('POST', url('/v2/dataIntegrations/employees', { companyID, preferredTaxCard }))
}

// export function putDataIntegrationsEmployees(
//   asynchronousTaskID: string,
//   employeesToImport: string[]
// ): Promise<RequestResponse<AsynchronousTask>> {
//   return secureRequest('PUT', url('/v2/dataIntegrations/employees/' + asynchronousTaskID), {
//     body: employeesToImport,
//   })
// }

export function getDataIntegrationLeaveRegistrationsExport(
  asynchronousTaskID: string
): Promise<RequestResponse<string[]>> {
  return secureRequest('GET', url('/v2/dataIntegrations/leaveRegistrations/export/' + asynchronousTaskID))
}

export type ExternalMappingFieldType = 'text' | 'number' | 'boolean' | 'multi' | 'date' | 'leaveType'
export type InternalMappingFieldType =
  | 'Company National ID'
  | 'Company Name'
  | 'Company ID'
  | 'Department ID Limit'
  | 'National ID'
  | 'Employee Number'
  | 'Email'
  | 'Address'
  | 'City'
  | 'Postal Code'
  | 'Bank Registration Number'
  | 'Bank Account Number'
  | 'Start Date'
  | 'Salary Fixed'
  | 'Salary Hourly'
  | 'Salary Rate'
  | 'Benefit Free Phone'
  | 'Benefit Lunch'
  | 'Benefit Health'
  | 'Tax Card Primary'
  | 'Tax Card Secondary'
  | 'Leave VacationAccrual'
  | 'Leave VacationFund'
  | 'Leave Sick'
  | 'Leave DayOff'
  | 'Leave PersonalDay'
  | 'Leave ExtraDay'
  | 'Leave Unpaid'

export type EmployeeImportMappingField = {
  field: InternalMappingFieldType
  externalField: string
  externalValue?: string
}

export type EmployeeImportFields = {
  rules: {
    field: InternalMappingFieldType
    allowedTypes: ExternalMappingFieldType[]
    required: boolean
    requireValue: boolean
    externalFieldName?: string
  }[]
  mapping: EmployeeImportMappingField[]
  externalFields: {
    externalField: string
    externalName?: string
    type: ExternalMappingFieldType
    values?: {
      value: string
      displayValue?: string
    }[]
  }[]
}

export function getDataIntegrationFields(companyID: string): Promise<RequestResponse<EmployeeImportFields>> {
  return secureRequest('GET', url('/v2/dataIntegrations/fields', { companyID }))
}

export function setDataIntegrationFields(
  companyID: string,
  fields: EmployeeImportMappingField[]
): Promise<RequestResponse<EmployeeImportMappingField[]>> {
  return secureRequest('PUT', url('/v2/dataIntegrations/fields', { companyID }), {
    body: {
      mapping: fields,
    },
  })
}
